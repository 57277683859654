import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery, useQueryClient } from "react-query";
import { API } from "../../clients/api";
import { useGlobal } from "../../contexts";
import { DevTool } from "@hookform/devtools";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'

function FieldSkeleton() {
  return (
    <div className="progress" style={{ height: "3rem" }}>
      <div
        className="progress-bar bg-secondary opacity-25 progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: "100%" }}
      ></div>
    </div>
  );
}

function TextField(props) {
  return (
    <>
      <label className="form-label" htmlFor={props.name}>
        {props.label}
      </label>
      {props.loading ? (
        <FieldSkeleton />
      ) : (
        <input
          type="text"
          id={props.name}
          className="form-control"
          placeholder={props.placeholder}
          {...props.register(props.name)}
        />
      )}
    </>
  );
}

function NumberField(props) {
  const handleKeyDown = (e) => {
    if (!e.key.match(/^[0-9]$/) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  }

  return (
    <>
      <label className="form-label" htmlFor={props.name}>
        {props.label}
      </label>
      {props.loading ? (
        <FieldSkeleton />
      ) : (
        <input
          type="text"
          id={props.name}
          className="form-control"
          placeholder={props.placeholder}
          {...props.register(props.name)}
          onKeyDown={handleKeyDown}
        /*  ref={() => { }}
         onChange={handleChange(onChange)}
         onBlur={handleChange(onBlur)} */
        />
      )}
    </>
  );
}

function DateField(props) {
  return (
    <>
      {props.loading ? (
        <FieldSkeleton />
      ) : (
        <Controller
          render={({ field }) => {
            const { value, ...props } = field

            return (
              <DatePicker
                {...props}
                className="form-control"
                selected={value ? new Date(value) : null}
                dateFormat='dd/MM/yyyy'
              />
            )
          }}
          name="birth_date"
          control={props.control}
        />
      )}
    </>
  );
}

function SelectCountryField(props) {
  const fieldProps = React.useMemo(() => props.register(props.name), []);

  const handleOnChange = (event) => {
    fieldProps.onChange(event);
    props.onChange(event.target.value);
  };

  return (
    <>
      {props.loading ? (
        <FieldSkeleton />
      ) : (
        <select
          id="selectCountry"
          className="form-select"
          style={{ color: "#000" }}
          {...fieldProps}
          onChange={handleOnChange}
        >
          {props.countries.map((country) => {
            return (
              <>
                <option selected={props.value} value={country.id}>
                  {country.country}
                </option>
              </>
            );
          })}
        </select>
      )}
    </>
  );
}

function SelectStateField(props) {
  return (
    <>
      {props.loading ? (
        <FieldSkeleton />
      ) : (
        <select
          id="selectRegion"
          className="form-select"
          style={{ color: "#000" }}
          {...props.register(props.name)}
        >
          {props.states
            ? props.states.map((state) => {
              return (
                <>
                  <option value={state.id}>{state.state}</option>
                </>
              );
            })
            : console.log("b")}
        </select>
      )}
    </>
  );
}
export function ProfileInfoForm(props) {
  const [thisState, setThisState] = React.useState("");
  // Query React
  const { token } = useGlobal();

  const { data: countries } = useQuery(
    "countries",
    async () => {
      const { data = [] } = await API.fetchCountry(token);

      return data;
    },
    {
      initialData: [],
    }
  );

  const { data: states, refetch: refetchState } = useQuery(
    "states",
    async () => {
      const { data = [] } = await API.fetchStateByCountryId(
        token,
        props.values.country_id
      );

      setThisState(data);

      return data;
    },
    {
      initialData: [],
      enabled: false,
    }
  );

  //Form Controller

  const statesByNewSelectedCountry = async (countryId) => {
    console.log("teste", countryId);
    const { data = [] } = await API.fetchStateByCountryId(token, countryId);
    setThisState(data);
  };

  const handleCountryOnChange = (countryId) => {
    statesByNewSelectedCountry(countryId);
  };

  const not = (value) => !value;

  const queryClient = useQueryClient();

  const disableSubmitButtonIfFormIsDirty = () => not(formState.isDirty);
  const onSubmit = async (formData) => {
    Swal.fire({
      title: 'Deseja editar dados de perfil?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await API.updateUserInfo(token, formData);

          if (data === 'Usuário atualizado com sucesso.') {
            toast.success('Sucesso ao atualizar perfil de usuário!');
            queryClient.invalidateQueries(['users', 'me'])
            return data;
          } else {
            toast.error('Falha ao atualizar perfil de usuário!');
          }
        } catch (error) {
          toast.error('Falha ao atualizar perfil de usuário!');
        }
      }
    })
  };

  const { register, handleSubmit, control, formState, setValue } = useForm({
    defaultValues: useMemo(() => props.values, [props]),
  });

  useEffect(() => {
    if (props.values.country_id) {
      refetchState();
    }

    Object.keys(props.values).forEach((key) => {
      setValue(key, props.values[key]);
    });
  }, [props.values]);
  console.log({ formState }, formState.isSubmitting)
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <TextField
                name="firstname"
                label="Primeiro nome"
                placeholder="Primeiro nome"
                loading={props.loading}
                register={register}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <TextField
                name="surname"
                label="Sobrenome"
                placeholder="Sobrenome"
                loading={props.loading}
                register={register}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <NumberField
                name="phone"
                label="Telefone"
                placeholder="Telefone"
                loading={props.loading}
                register={register}
              />

              <small id="emailHelp" className="form-text text-muted">DDD + telefone, sem espaço. Ex: 11987654321</small>

            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label" htmlFor="birthday">
                Aniversário
              </label>
              <DateField loading={props.loading} control={control} />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <TextField
                name="address_1"
                label="Endereço Linha 1"
                placeholder="Endereço"
                loading={props.loading}
                register={register}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <TextField
                name="address_2"
                label="Endereço Linha 2"
                placeholder="Endereço"
                loading={props.loading}
                register={register}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label" htmlFor="selectCountry">
                País
              </label>
              <SelectCountryField
                name="country_id"
                loading={props.loading}
                register={register}
                countries={countries}
                value={props.values.country_id}
                onChange={handleCountryOnChange}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label" htmlFor="selectRegion">
                Estado
              </label>
              <SelectStateField
                name="state_id"
                register={register}
                token={token}
                loading={props.loading}
                states={thisState}
              />
            </div>
          </div>

          <div className="col-12">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={disableSubmitButtonIfFormIsDirty() || formState.isSubmitting}
            >
              Atualizar perfil
            </button>
          </div>
        </div>
      </form>

      <DevTool control={control} />
    </>
  );
}

ProfileInfoForm.propTypes = {
  loading: PropTypes.bool,
};

TextField.propTypes = {
  loading: PropTypes.bool,
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

DateField.propTypes = {
  control: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SelectCountryField.propTypes = {
  countries: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  register: PropTypes.func.isRequired,
};

SelectStateField.propTypes = {
  loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
};

SelectStateField.defaultProps = {
  loading: true,
};
SelectCountryField.defaultProps = {
  onChange: () => { },
  loading: true,
};
DateField.defaultProps = {
  loading: true,
};
TextField.defaultProps = {
  loading: true,
  placeholder: "",
};

ProfileInfoForm.defaultProps = {
  loading: true,
  values: { firstname: "" },
};
