import { useRef } from 'react';
import { toast } from 'react-toastify';
import { SignBanner, AccountCard } from '../../components';

export const RecoverAccount = () => {
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  const handleRecoverAccount = (e) => {
    e.preventDefault();

    toast.error('Falha ao realizar sua solicitação. Verifique e tente novamente!', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    passwordRef.current.value = '';
    passwordConfirmRef.current.value = '';
  }

  return (
    <div className='account-page-wrapper'>
      <SignBanner />

      <AccountCard>
        <p className='display-5 text-black'>Recuperar conta</p>

        <form className='row' onSubmit={handleRecoverAccount}>
          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="password">Nova senha</label>
            <input type="password" id="password" className="form-control" placeholder="Senha" required />
          </div>

          <div className="mb-4 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="newpassword">Confirmar nova senha</label>
            <input type="password" id="newpassword" className="form-control" placeholder="Senha" required />
          </div>

          <div className="mb-5 col-12">
            <button className='btn btn-primary text-uppercase' style={{ width: '100%' }}>Recuperar</button>
          </div>
        </form>
      </AccountCard>
    </div>
  );
}