import { forwardRef } from "react"

export const SearchBar = forwardRef((props, ref) => {
  return (
    <form className="d-flex align-items-center">
      <span className="position-absolute ps-3 search-icon">
        <i className="fe fe-search"></i>
      </span>
      <input
        ref={ref}
        type='search'
        className="form-control form-control-sm ps-6"
        placeholder="Pesquisar"
      />
    </form>
  )
})