import Logo from '../../../assets/images/organon/logo-blue.png';

export const AccountCard = ({ children }) => (
  <div className="card account">
    <div className="card-body">
      <div className="account-logo mb-7">
        <img src={Logo} alt="Logo Organon" className='mb-3' />
        <p className='fs-5 fw-bold text-dark'>Plataforma de Treinamento</p>
      </div>

      {children}
    </div>
  </div>
)