import { useState, useContext, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Tab, Nav, Accordion, useAccordionButton, AccordionContext } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useGlobal } from '../../contexts';
import { useParams, useNavigate } from 'react-router-dom';
import { API } from '../../clients/api';
import { toast } from 'react-toastify';
import { PageContent, PageWrapper, Header, Footer, Navbar, Star, SearchBar, CheckedItem } from "../../components"
import { getStarProps } from "../../helpers";

import profilePic1 from '../../assets/images/avatar/avatar-1.jpg';
import profilePic2 from '../../assets/images/avatar/avatar-2.jpg';
import profilePic3 from '../../assets/images/avatar/avatar-3.jpg';
import profilePic4 from '../../assets/images/avatar/avatar-4.jpg';
import profilePic5 from '../../assets/images/avatar/avatar-5.jpg';

function CustomToggle({ children, subtitle, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div className='fw-medium' onClick={decoratedOnClick} style={{ padding: '1.15rem 1.65rem' }}>
      <h3 className="mb-0 fs-4">
        <a
          style={{ color: '#635E7C' }}
          className="d-flex align-items-center text-decoration-none"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <span className="me-auto" style={{ width: 'calc(100% - 40px)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {children}
          </span>
          <span className="collapse-toggle ms-4" style={isCurrentEventKey ? { transform: 'rotate(0deg)' } : {}}>
            <i className="fe fe-chevron-up"></i>
          </span>
        </a>
      </h3>

      <p className="mb-0 text-gray"><small>{subtitle}</small></p>
    </div>
  )
}

const LessonMedia = (({
  id,
  teacher: { firstname, surname, profile_picture } = {},
  media_url,
  title
}) => {
  const [file, setFile] = useState({});
  const { icon, bgColor, color } = fileTypeIcon['img'];
  const type = media_url.split('.').pop() || 'img';

  useEffect(() => {
    if (media_url) {
      (async () => {
        const response = await fetch(media_url);
        const blob = await response.blob();

        setFile({
          blob,
          objectUrl: URL.createObjectURL(blob)
        })
      })()
    }

    return () => {
      if (file.objectUrl) {
        URL.revokeObjectURL(file.objectUrl);
      }
    }
  }, [media_url])

  const handleDownloadMaterial = () => {
    const link = document.createElement("a");
    link.download = title;
    link.href = file.objectUrl;

    link.click();
  }

  const size = file.blob ? file.blob.size : 0;

  return (
    <li key={id} className="list-group-item file-list_item px-0">
      <span className='file-list_item-icon' style={{ background: bgColor, color }}>
        <i className={`fe fe-${icon}`}></i>
      </span>

      <span className='fw-medium text-gray file-list_item-title'>
        {title}
      </span>

      <span className='fw-medium text-gray file-list_item-size'>
        {Number(size / 1024).toFixed(1)}MB
      </span>

      <div>
        <div className="avatar avatar-sm avatar-primary me-2">
          {
            profile_picture
              ? <img alt="avatar" src={profile_picture} className="rounded-circle" />
              : <span className="avatar-initials rounded-circle">{getUsernameInitial(`${firstname} ${surname}`)}</span>
          }
        </div>
      </div>

      <div className='d-flex'>
        <button onClick={handleDownloadMaterial} type="button" className="btn btn-icon btn-sm btn-white text-center rounded-circle">
          <i className="fe fe-download fs-4 text-gray"></i>
        </button>

        <a href={media_url} target='_blank' className="btn btn-icon btn-sm btn-white text-center rounded-circle ms-1">
          <i className="fe fe-link fs-4 text-gray"></i>
        </a>
      </div>

      <div className='ms-auto'>
        <button type="button" className="btn btn-icon btn-sm btn-white text-center rounded-circle">
          <i className="fe fe-more-vertical fs-4 text-gray"></i>
        </button>
      </div>
    </li>
  )
})

const levels = {
  beignners: {
    label: 'Iniciante',
    rating: 1
  },
  intermediate: {
    label: 'Intermediário',
    rating: 2
  },
  advanced: {
    label: 'Avançado',
    rating: 3
  }
}


export const Aula = () => {
  const navigate = useNavigate();
  const { courseId, lessonId } = useParams();
  const { isToggled, handleToggle, token } = useGlobal();
  const [currentTab, setCurrentTab] = useState('description');
  const videoRef = useRef();

  const { data: course, isLoading: isCourseLoading } = useQuery(
    ['course', courseId],
    async () => {
      const { data = {} } = await API.fetchCourseById(token, courseId);

      return typeof data === 'object' ? data : {};
    },
    {
      initialData: {},
    });

  const { data: lesson, isLoading: isLessonLoading } = useQuery(
    ['lesson', lessonId],
    async () => {
      const { data } = await API.fetchLessonById(token, lessonId);

      return typeof data === 'object' ? data : {};
    },
    {
      initialData: {},
    });

  const queryClient = useQueryClient();

  const markCourseFn = async (courseId) => {
    const { data } = await API.markCourse(token, courseId);

    return data;
  }

  const unmarkCourseFn = async (courseId) => {
    const { data } = await API.unmarkCourse(token, courseId);

    return data;
  }

  const { mutate: markCourse } = useMutation(markCourseFn, {
    onMutate: async () => {
      await queryClient.cancelQueries(['course', courseId])
      const previousTodos = queryClient.getQueryData(['course', courseId])
      queryClient.setQueryData(['course', courseId], old => ({ ...old, is_marked: true }))
      return { previousTodos }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['course', courseId], context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries(['course', courseId])
    },
  })

  const { mutate: unmarkCourse } = useMutation(unmarkCourseFn, {
    onMutate: async () => {
      await queryClient.cancelQueries(['course', courseId])
      const previousTodos = queryClient.getQueryData(['course', courseId])
      queryClient.setQueryData(['course', courseId], old => ({ ...old, is_marked: false }))
      return { previousTodos }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['course', courseId], context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries(['course', courseId])
    },
  })


  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  const handleStartLesson = async () => await API.vinculateLesson(token, lessonId, { time_stopped: 0, is_concluded: false });

  const handleEndLesson = () => {
    API.finishLesson(token, lessonId, { courseId: Number(courseId), is_concluded: true })
      .then(() => {
        const content = course.content.find((item) => item.id === lesson.lesson_group_id);
        let nextLesson = content.lessons.find((item) => item.order === (lesson.order + 1));

        if (!nextLesson) {
          const nextContent = course.content.find((item) => item.order === (content.order + 1))
          nextLesson = nextContent && nextContent.lesson[0];
        }

        if (nextLesson) {
          toast.success(`Aula concluida! Proxima aula ${nextLesson.title}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          queryClient.invalidateQueries(['course', courseId])
          setTimeout(() => navigate(`/curso/${courseId}/aula/${nextLesson.id}`), 5000)
        } else {
          toast.success('Curso Concluido!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => navigate(`/curso/${courseId}`), 3000)
        }
      });
  }
  const handleProgressLesson = ({ playedSeconds }) => localStorage.setItem('video', JSON.stringify({ lessonId, playedSeconds }))
  const handleReady = () => {
    const storage = localStorage.getItem('video');

    if (storage) {
      const progress = JSON.parse(storage);

      if (progress.lessonId === lessonId) {
        videoRef.current.seekTo(Number(progress.playedSeconds), 'seconds')
      }
    }

  }
  const handleClickLesson = (lessonId) => () => navigate(`/curso/${courseId}/aula/${lessonId}`);

  const level = lesson && lesson.difficulty && levels.hasOwnProperty(lesson.difficulty) ? levels[lesson.difficulty] : levels.beignners;
  console.log({ lesson })
  return (
    <PageWrapper className={isToggled ? 'toggled' : ''}>
      <Navbar />

      <PageContent className='lesson'>
        <Header
          onToggle={handleToggle}
        >
          header
        </Header>
        <div className="container-xl mb-12">
          <div className='row player'>
            <div className='col-12'>
              <div className='player-wrapper' >
                <ReactPlayer
                  ref={videoRef}
                  className='react-player'
                  width='100%'
                  height='100%'
                  url={lesson ? lesson.video : ''}
                  controls={true}
                  onReady={handleReady}
                  onProgress={handleProgressLesson}
                  onStart={handleStartLesson}
                  onEnded={handleEndLesson}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-8 mb-4'>
              <div className='card card-info'>
                {
                  !isCourseLoading &&
                  !isLessonLoading &&
                  course.id &&
                  lesson.id && (
                    <>
                      <div className='card-header border-0'>
                        <div className='card-info-title'>
                          <h1>
                            {lesson.title}
                          </h1>

                          <span className='text-secondary'>
                            <a onClick={() => course.is_marked ? unmarkCourse(course.id) : markCourse(course.id)} className="text-muted bookmark mark-button">
                              <i
                                className="material-icons"
                                style={{
                                  color: course.is_marked ? "#754FFE" : "",
                                }}
                              >
                                bookmark
                              </i>
                            </a>
                          </span>
                        </div>

                        <div className='fw-medium mb-4 card-info-description'>
                          {
                            course.is_rating_enabled && (
                              <span>
                                <span style={{ lineHeight: 1 }}>
                                  {
                                    Array(5)
                                      .fill(null)
                                      .map((val, index) => (
                                        <Star
                                          key={index}
                                          {...getStarProps(
                                            index,
                                            parseFloat(course.ratingAverage)
                                          )}
                                        />
                                      ))
                                  }
                                </span>
                                <span className='ms-1'>
                                  {parseFloat(course.ratingAverage)}
                                </span>
                              </span>
                            )
                          }

                          <span>
                            <svg style={{ lineHeight: 1 }} className="me-1 mt-n1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect x="3" y="8" width="2" height="6" rx="1" fill={level.rating >= 1 ? "#754FFE" : "#DBD8E9"} />
                              <rect x="7" y="5" width="2" height="9" rx="1" fill={level.rating >= 2 ? "#754FFE" : "#DBD8E9"} />
                              <rect x="11" y="2" width="2" height="12" rx="1" fill={level.rating >= 3 ? "#754FFE" : "#DBD8E9"} />
                            </svg>
                            {level.label}
                          </span>
                        </div>

                        <div className='d-flex justify-content-between mb-2'>
                          {
                            course.author && (
                              <a className="rounded-circle d-flex align-items-center" href="#" role="button">
                                <div className="avatar avatar-md me-2">
                                  <img alt="avatar" src={profilePic2} className="rounded-circle" />
                                </div>

                                <div className='' style={{ lineHeight: '1' }}>
                                  <p className='mb-1 fw-medium'>Inventore Veritatis</p>
                                  <p className='mb-0 text-gray'><small>@inventore_veritatis</small></p>
                                </div>
                              </a>
                            )
                          }

                          {/* <button className='btn btn-outline-secondary btn-sm fw-medium follow-button'>
                            Seguir
                          </button> */}
                        </div>

                      </div>
                      <div className='card-footer'>
                        <Nav onSelect={handleSelectTab} id="course-intro-tab" variant="pills" className="nav-top-line-tab border-top-0">
                          <Nav.Item className='my-0 mx-3'>
                            <Nav.Link
                              className='lesson-nav-link'
                              active={isCurrentTab('description')}
                              eventKey="description"
                            >
                              Descrição
                            </Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item className='my-0 mx-3'>
                            <Nav.Link
                              className='lesson-nav-link'
                              active={isCurrentTab('avaliations')}
                              eventKey="avaliations"
                            >
                              Avaliações
                            </Nav.Link>
                          </Nav.Item> */}
                          <Nav.Item className='my-0 mx-3'>
                            <Nav.Link
                              className='lesson-nav-link'
                              active={isCurrentTab('material')}
                              eventKey="material"
                            >
                              Material de apoio
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </>
                  )
                }
              </div>

              <div className='card card-description'>

                {
                  !isCourseLoading &&
                  !isLessonLoading &&
                  lesson.id &&
                  course.id && (
                    <div className={`card-body ${isCurrentTab('material') ? 'p-5' : ''}`}>
                      <Tab.Content>
                        <Tab.Pane active={isCurrentTab('description')}>
                          <h4 className='mb-3 fs-6-5 text-dark fw-bold'>
                            Descrição
                          </h4>

                          <p className='fw-medium mb-4 text-gray' dangerouslySetInnerHTML={{ __html: lesson.description }}></p>

                          <h4 className='mb-3 fs-6-5 text-dark fw-bold fw-bold'>
                            O que vai aprender
                          </h4>

                          <ul className='fw-medium learn-list'>
                            {
                              lesson.learnings && lesson.learnings.map((value, index) => (
                                <CheckedItem key={`${value}-${index}`}>{value}</CheckedItem>
                              ))
                            }
                          </ul>

                          <p className='fw-medium text-gray' dangerouslySetInnerHTML={{ __html: lesson.footer }}></p>
                        </Tab.Pane>
                        {/*  <Tab.Pane className='card-description_pane-avaliations' active={isCurrentTab('avaliations')}>
                          <h4 className='mb-6 fs-6-5 text-dark fw-bold'>
                            Como os alunos avaliaram esse curso
                          </h4>

                          <div className='d-flex mb-5'>
                            <div className='text-center' style={{ flex: 4 }}>
                              <p className='display-2 text-dark'>
                                4,5
                              </p>

                              <p className="mb-0">
                                <span style={{ lineHeight: 1 }}>
                                  <Star full />
                                  <Star full />
                                  <Star full />
                                  <Star full />
                                  <Star half />
                                </span>
                              </p>

                              <p className="mb-0">
                                <small>
                                  (Baseado em 27 comentários)
                                </small>
                              </p>
                            </div>
                            <ul className='list-ranking'>
                              {
                                courseMock.ranking.map(({ percentage }, rankingIndex) => {
                                  const total = 5 - rankingIndex;

                                  // mockup temp
                                  const progress = 50 + ((total - 1) * 10);

                                  return (
                                    <li className='d-flex align-items-center mb-2' style={{ lineHeight: 1 }}>
                                      {
                                        Array(5).fill(null).map((val, index) => {
                                          const currentStar = index + 1;
                                          const full = total >= currentStar;

                                          return (
                                            <Star key={index} full={full} />
                                          )
                                        })
                                      }
                                      <span className='ms-2'>
                                        {percentage}%
                                      </span>

                                      <div style={{ width: '2rem', height: '0.4rem' }} className="progress ms-auto">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, background: '#FFAA46' }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                          <hr></hr>

                          <h4 className='mb-3 fs-6-5 text-dark fw-bold'>
                            Avaliações
                          </h4>

                          <div className='mb-5'>
                            <SearchBar placeholder='Revisão de pesquisa' />
                          </div>

                          <div className='d-flex'>
                            <a className="rounded-circle d-flex align-items-center gap-3" href="#" role="button">
                              <div className="avatar avatar-lg me-2">
                                <img alt="avatar" src={profilePic2} className="rounded-circle" />
                              </div>

                              <div className='' style={{ lineHeight: '1' }}>
                                <p className='mb-2'>
                                  <span className='fw-bold'>Max Hawkins</span>
                                  <span className='ms-2'><small>2 dias atrás</small></span>
                                </p>

                                <p className="mb-0">
                                  <span style={{ lineHeight: 1 }}>
                                    <Star full style={{ fontSize: '0.8rem' }} />
                                    <Star full style={{ fontSize: '0.8rem' }} />
                                    <Star full style={{ fontSize: '0.8rem' }} />
                                    <Star full style={{ fontSize: '0.8rem' }} />
                                    <Star full style={{ fontSize: '0.8rem' }} />
                                  </span>
                                </p>
                              </div>
                            </a>
                          </div>

                          <div style={{ paddingLeft: '5rem' }}>
                            <p className='mb-3'>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget tellus ut nisl consectetur pellentesque rhoncus vitae nibh. Sed fringilla dui nec ultrices semper.
                            </p>

                            <div>
                              <p>Esta avaliação foi útil?</p>

                              <div>
                                <button className='btn btn-xs btn-primary'>
                                  Sim
                                </button>

                                <button className='btn btn-xs btn-outline-secondary ms-2'>
                                  Não
                                </button>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane> */}
                        <Tab.Pane className='overflow-auto' active={isCurrentTab('material')}>
                          <h4 className='mb-3 fs-6-5 text-dark fw-bold'>
                            Lorem ipsum dolor
                          </h4>

                          <p className='fw-medium mb-4 text-gray'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                          </p>

                          <ul className="list-group list-group-flush file-list" style={{ minWidth: 440 }}>
                            {
                              lesson && lesson.lesson_medias.map((data, index) => <LessonMedia {...data} title={`Material ${index + 1}`} />)
                            }
                          </ul>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  )
                }
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='card'>
                {
                  !isCourseLoading && course.id && (
                    <Accordion defaultActiveKey="0">
                      <ul className="list-group list-group-flush lessons-list">
                        {
                          course.content.map(({ name, lessons }, index) => {
                            const totalTime = lessons.reduce((acc, curr) => acc + Number(curr.duration), 0);
                            const formatedTotalTime = formatMinuteSeconds(totalTime);
                            const concludedLessons = lessons.reduce((prev, curr) => prev + curr.is_concluded, 0);
                            const percentage = lessons.length ? concludedLessons * 100 / lessons.length : 0;

                            return (
                              <li key={`${name}-${index}`} className="list-group-item lessons-list_item">
                                <Accordion.Item className='border-0' eventKey={index}>
                                  <CustomToggle
                                    eventKey={index}
                                    subtitle={`${lessons.length} vídeos (${formatedTotalTime.hours} hora e ${formatedTotalTime.minutes} minutos)`}>{name}</CustomToggle>
                                  <Accordion.Body eventKey={index} style={{ padding: 0 }}>
                                    <ul className="list-group list-group-flush tasks-list">
                                      <li className="list-group-item tasks-list_item d-block">
                                        <div className="progress">
                                          <div className="progress-bar bg-success rounded-pill" role="progressbar" style={{ width: `${percentage}%` }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p className="mb-0 text-gray"><small>{percentage.toFixed()}% Concluído</small></p>
                                      </li>

                                      {
                                        lessons.map(({ id, title, is_concluded, duration }) => {
                                          const status = is_concluded ? 'finished' : 'open';
                                          const icon = {
                                            class: 'material-icons',
                                            name: 'play_arrow'
                                          }

                                          const formatedTime = formatMinuteSeconds(Number(duration));

                                          return (
                                            <li key={id} className={`list-group-item tasks-list_item ${lessonId == id ? 'active' : ''}`}>
                                              <button onClick={handleClickLesson(id)} type="button" className={`btn btn-play btn-icon btn-white rounded-circle me-2 ${status}`}>
                                                <span className={icon.class}>
                                                  {icon.name}
                                                </span>
                                              </button>
                                              <span className='fw-medium fs-4 text-truncate me-1'>{title}</span>

                                              <p className='timer text-nowrap'><small>{`${formatedTime.minutes}m ${formatedTime.seconds}s`}</small></p>
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </Accordion>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </PageContent>
    </PageWrapper >
  )
}

const formatMinuteSeconds = (value) => ({
  hours: Math.floor(value / 3600),
  minutes: Math.floor((value % 3600) / 60),
  seconds: value % 3600 % 60
})

const fileTypeIcon = {
  img: {
    bgColor: '#E1F6FF',
    color: '#29BAF9',
    icon: 'file'
  },
  word: {
    bgColor: '#D0F5EA',
    color: '#3BD2A7',
    icon: 'file'
  },
  pdf: {
    bgColor: '#FAD9D8',
    color: '#E95C59',
    icon: 'file'
  },
  txt: {
    bgColor: '#FFEEDA',
    color: '#FFB761',
    icon: 'file'
  },
  presentation: {
    bgColor: '#FAD9D8',
    color: '#E5423F',
    icon: 'file'
  },
  spreadsheet: {
    bgColor: '#D0F5EA',
    color: '#3BD2A7',
    icon: 'file'
  },
}

const getUsernameInitial = (name = '') => {
  const [firstName = '', lastName = ''] = name.split(' ');

  return `${firstName[0] || ''}${lastName[0] || ''}`;
}