import { useState } from "react";
import { useGlobal } from "../../contexts";
import {
  Navbar,
  Header,
  PageWrapper,
  PageContent,
  ProfileHeader,
  Card,
  Footer,
  LoadingSpinner,
} from "../../components";
import { Nav } from "react-bootstrap";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API } from "../../clients/api";
import { useNavigate } from "react-router-dom";
import defaultProfilePicture from "../../assets/images/avatar/default.jpg";
import produce from "immer";


import ImageItem6 from "../../assets/images/organon/item-6.png";
import defaultAvatar from "../../assets/images/avatar/default.jpg";

const UserInfo = (field) => {
  const { user } = useGlobal();

  if (!user || !user.id) {
    return "";
  }

  if (field == "fullname") {
    return `${user.firstname} ${user.surname}`;
  } else if (field == "description") {
    return `@${user.firstname}_${user.surname}`.split(" ").join("_");
  } else {
    return user[field];
  }
};

const FooterChecked = ({ author, is_marked, onCheck }) => (
  <div className="card-footer">
    <div className="row align-items-center g-0">
      <div className="col-auto">
        <img
          src={author.avatar || defaultProfilePicture}
          className="rounded-circle avatar-xs"
          alt="Foto de perfil do autor"
        />
      </div>
      <div className="col ms-2">
        <span>{author.fullName}</span>
      </div>
      <div className="col-auto">
        <a onClick={onCheck} className="text-muted bookmark mark-button">
          <i
            className="material-icons"
            style={{
              fontSize: "1rem",
              verticalAlign: "middle",
              color: "#754FFE"
            }}
          >
            bookmark
          </i>
        </a>
      </div>
    </div>
  </div>
);

const FooterInProgress = ({ author, is_marked, onCheck, percentage }) => (
  <div className="card-footer">
    <div className="row align-items-center g-0 mb-4">
      <div className="col-auto">
        <img
          src={author.avatar || defaultProfilePicture}
          className="rounded-circle avatar-xs"
          alt="Foto de perfil do autor"
        />
      </div>
      <div className="col ms-2">
        <span>{author.fullName}</span>
      </div>
      <div className="col-auto">
        <a onClick={onCheck} className="text-muted bookmark mark-button">
          <i
            className="material-icons"
            style={{
              fontSize: "1rem",
              verticalAlign: "middle",
              color: is_marked ? "#754FFE" : "",
            }}
          >
            bookmark
          </i>
        </a>
      </div>
    </div>
    <div className="progress" style={{ height: "0.35rem" }}>
      <div
        className="progress-bar bg-success"
        role="progressbar"
        style={{ width: `${percentage}%` }}
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
);

const Button = ({ onClickButton }) => (
  <button
    onClick={onClickButton}
    className="btn btn-primary btn-sm bg-blue border-blue"
  >
    Configurações de conta
  </button>
);

export const Painel = () => {
  const navigate = useNavigate();
  const { isToggled, handleToggle, token } = useGlobal();
  const [currentTab, setCurrentTab] = useState("marked");
  const [isLoading, setisLoading] = useState(false);

  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;
  const CategoriesCard = ({ show }) => {
    const { data: markeds } = useQuery(
      ["courses", "marked"],
      async () => {
        setisLoading(true);
        const { data = [] } = await API.fetchMarkedCourses(token);
        setisLoading(false);

        return Array.isArray(data) ? data : [];
      },
      { enabled: isCurrentTab("marked"), initialData: [] }
    );

    const { data: inProgress } = useQuery(
      ["courses", "inProgress"],
      async () => {
        setisLoading(true);
        const { data = [] } = await API.fetchInProgressCourses(token);
        setisLoading(false);
        return Array.isArray(data) ? data : [];
      },
      { enabled: isCurrentTab("inProgress"), initialData: [] }
    );

    const coursesArray = isCurrentTab("marked") ? markeds : inProgress;
    return coursesArray.map(({ id, ratingAverage, ratings, conclusion_percentage, course = {} }) => (
      <div
        key={course.id}
        style={{ display: show ? "flex" : "none" }}
        className="col-sm-6 col-lg-4"
      >
        <Card
          id={course.id}
          title={course.title}
          is_marked={course.is_marked}
          subscribers={ratings}
          image={course.thumbnail ? course.thumbnail.file_url : ImageItem6}
          difficulty={course.difficulty}
          stars={course.is_rating_enabled ? ratingAverage : -1}
          percentage={conclusion_percentage}
          author={{
            fullName: `${course.author ? course.author.firstname : ''} ${course.author ? course.author.surname : ''}`,
            avatar: course.author && course.author.profile_picture ? course.author.profile_picture : defaultAvatar
          }}
          onCheck={
            () => {
              course.is_marked || isCurrentTab("marked")
                ? unmarkCourse({ courseId: course.id, type: currentTab })
                : markCourse({ courseId: course.id, type: currentTab })
            }
          }
          Components={{
            Footer: isCurrentTab("marked") ? FooterChecked : FooterInProgress,
          }}
          link={`/curso/${course.id}`}
        />
      </div>
    ));
  };

  const queryClient = useQueryClient();

  const markCourseFn = async ({ courseId }) => {
    const { data } = await API.markCourse(token, courseId);

    return data;
  }

  const unmarkCourseFn = async ({ courseId }) => {
    const { data } = await API.unmarkCourse(token, courseId);

    return data;
  }

  const { mutate: markCourse } = useMutation(markCourseFn, {
    onMutate: async ({ courseId, type }) => {
      await queryClient.cancelQueries(["courses", type])
      const previousTodos = queryClient.getQueryData(["courses", type])

      queryClient.setQueryData(
        ["courses", type],
        produce((draft) => {
          const course = draft.find(({ course }) => course.id === courseId);

          course.is_marked = true;
        })
      )

      return { previousTodos }
    },
    onError: (err, { type }, context) => {
      queryClient.setQueryData(["courses", type], context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries(["courses"])
    },
  })

  const { mutate: unmarkCourse } = useMutation(unmarkCourseFn, {
    onMutate: async ({ courseId, type }) => {
      await queryClient.cancelQueries(["courses", type])
      const previousTodos = queryClient.getQueryData(["courses", type])

      queryClient.setQueryData(
        ["courses", type],
        produce((draft) => {
          if (type === 'isMarked') {
            const course = draft.find(({ course }) => course.id === courseId);


            course.is_marked = false;
          } else {
            const courseIndex = draft.findIndex(({ course }) => course.id === courseId);

            draft.splice(courseIndex, 1);
          }
        })
      )

      return { previousTodos }
    },
    onError: (err, { type }, context) => {
      queryClient.setQueryData(["courses", type], context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries(["courses"])
    },
  })

  return (
    <PageWrapper className={isToggled ? "toggled" : ""}>
      <Navbar />

      <PageContent className="painel">
        <Header onToggle={handleToggle} />
        <div className="container-xl">
          <div className="row my-5">
            <div className="col-md-12">
              <ProfileHeader
                avatar={UserInfo("profile_picture")}
                title={UserInfo("fullname")}
                description={UserInfo("description")}
                onClickButton={() => navigate('/perfil')}
                components={{ Button }}
              />
            </div>
          </div>

          <div className="row g-3 cards mb-12">
            <div className="col-12">
              <Nav
                onSelect={handleSelectTab}
                variant="pills"
                className="nav-line-bottom mb-3"
              >
                <Nav.Item>
                  <Nav.Link
                    className="border-2 px-0 me-6"
                    active={isCurrentTab("marked")}
                    eventKey="marked"
                  >
                    Marcados
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="border-2 px-0"
                    active={isCurrentTab("inProgress")}
                    eventKey="inProgress"
                  >
                    Em andamento
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <LoadingSpinner show={isLoading} />
            {CategoriesCard({ show: !isLoading })}
          </div>
        </div>
        <Footer />
      </PageContent>
    </PageWrapper>
  );
};
