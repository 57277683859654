import { useGlobal } from "../../contexts";
import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from "react-query";
import { useMediaQuery } from "react-responsive";

import {
  PageWrapper,
  PageContent,
  Footer,
  Navbar,
  Header,
  Card,
  CardRow,
  Star,
  LoadingSpinner,
} from "../../components";
import { getStarProps } from "../../helpers";
import { API } from "../../clients/api";
import { useInView } from "react-intersection-observer";
import ImageItem6 from "../../assets/images/organon/item-6.png";
import { useEffect, useState } from "react";
import produce from "immer";

const filters = {
  stars: [1.0, 2.0, 3.0, 4.0, 5.0],
};

const CardFooter = ({ is_marked, profile, onCheck }) => (
  <div className="card-footer">
    <div className="row align-items-center g-0">
      <div className="col-auto">
        <img
          src={profile.avatar}
          className="rounded-circle avatar-xs"
          alt=""
        />
      </div>
      <div className="col ms-2">
        <span>{profile.name}</span>
      </div>
      <div className="col-auto">
        <a onClick={onCheck} className="text-muted bookmark mark-button">
          <i
            className="material-icons"
            style={{
              fontSize: "1rem",
              verticalAlign: "middle",
              color: is_marked ? "#754FFE" : "",
            }}
          >
            bookmark
          </i>
        </a>
      </div>
    </div>
  </div>
);

export const Lista = () => {
  const { isToggled, handleToggle, token } = useGlobal();
  const [listType, setListType] = useState('grid');
  const { ref, inView, entry } = useInView();
  const [filter, setFilter] = useState({});
  const isMiddle = useMediaQuery({ maxWidth: 940 });

  const { data: categoriesNames = [] } = useQuery(
    ["categories", "names"],
    async () => {
      const res = await API.fetchCategoriesNames(token);

      return Array.isArray(res.data) ? res.data : [];
    },
    {
      initialData: [],
    }
  );

  const { data: groupsNames = [] } = useQuery(
    ["groups", "names"],
    async () => {
      const res = await API.fetchGroupsNames(token);

      return Array.isArray(res.data) ? res.data : [];
    },
    {
      initialData: [],
    }
  );

  const {
    data: courses,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(
    ["courses", filter],
    async ({ queryKey: [key, filter], pageParam = 1 }) => {
      const response = await API.fetchCourses(token, {
        ...filter,
        page: pageParam,
        coursesLimit: 4,
      });
      return { data: typeof response.data === 'object' ? response.data : {}, nextCursor: pageParam + 1 };
    },
    {
      initialData: [],
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    }
  );

  useEffect(() => {
    if (!entry?.isVisible && inView && courses.pages) {
      fetchNextPage();
    }
  }, [inView]);

  const handleChangeCategoryFilter = (id) => (e) => {
    setFilter(
      produce((draft) => {
        if (e.target.checked) {
          if (!draft.categories) {
            draft.categories = [id];
          } else {
            draft.categories.push(id);
          }
        } else {
          const index = draft.categories.findIndex((el) => el === id);
          draft.categories.splice(index, 1);
        }

        if (!draft.categories.length) {
          delete draft.categories;
        }
      })
    );
  };
  const handleChangeRatingFilter = (e) => {
    setFilter(
      produce((draft) => {
        if (draft.rating && draft.rating === Number(e.target.value)) {
          delete draft.rating;
        } else {
          draft.rating = Number(e.target.value);
        }
      })
    );
  };

  const handleChangeGroupFilter = (id) => (e) => {
    setFilter(
      produce((draft) => {
        if (e.target.checked) {
          if (!draft.groups) {
            draft.groups = [id];
          } else {
            draft.groups.push(id);
          }
        } else {
          const index = draft.groups.findIndex((el) => el === id);
          draft.groups.splice(index, 1);
        }

        if (!draft.groups.length) {
          delete draft.groups;
        }
      })
    );
  };

  let allCourses = [];
  let totalCourses = 0;

  const handleChangeOrder = (e) => {
    setFilter(
      produce((draft) => {
        if (e.target.value) {
          draft.order = e.target.value.split(',');
        } else {
          delete draft.order;
        }
      })
    );
  }

  if (courses && courses.pages) {
    allCourses = courses.pages.flatMap(
      (page) => page && page.data && page.data.courses
    );
    // * Busca o total de cursos a partir da ultima página
    totalCourses = courses.pages[courses.pages.length - 1].data.total;
  }

  const CardComponent = listType === 'row' && !isMiddle ? CardRow : Card;

  const queryClient = useQueryClient();

  const markCourseFn = async ({ courseId }) => {
    const { data } = await API.markCourse(token, courseId);

    return data;
  }

  const unmarkCourseFn = async ({ courseId }) => {
    const { data } = await API.unmarkCourse(token, courseId);

    return data;
  }

  const { mutate: markCourse } = useMutation(markCourseFn, {
    onMutate: async ({ courseId, pageIndex }) => {
      await queryClient.cancelQueries(["courses", filter])
      const previousTodos = queryClient.getQueryData(["courses", filter])

      queryClient.setQueryData(
        ["courses", filter],
        produce((draft) => {
          let course = {};

          for (const page of draft.pages) {
            const target = page.data.courses.find((course) => course.id === courseId);

            if (target) {
              course = target
            }
          }

          course.is_marked = true;
        })
      )

      return { previousTodos }
    },
    onError: (err, { type }, context) => {
      queryClient.setQueryData(["courses", filter], context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries(["courses", filter])
    },
  })

  const { mutate: unmarkCourse } = useMutation(unmarkCourseFn, {
    onMutate: async ({ courseId, pageIndex }) => {
      await queryClient.cancelQueries(["courses", filter])
      const previousTodos = queryClient.getQueryData(["courses", filter])

      queryClient.setQueryData(
        ["courses", filter],
        produce((draft) => {
          let course = {};

          for (const page of draft.pages) {
            const target = page.data.courses.find((course) => course.id === courseId);

            if (target) {
              course = target
            }
          }

          course.is_marked = false;
        })
      )

      return { previousTodos }
    },
    onError: (err, { type }, context) => {
      queryClient.setQueryData(["courses", filter], context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries(["courses", filter])
    },
  })

  return (
    <PageWrapper className={isToggled ? "toggled" : ""}>
      <Navbar />

      <PageContent>
        <Header onToggle={handleToggle}>header</Header>

        <div>
          <div className="section-list mb-5">
            <div className="container-xl">
              <h1
                className="text-white display-4 mb-0"
                style={{ paddingLeft: "78px" }}
              >
                Lista de conteúdo
              </h1>
            </div>
          </div>

          <div className="container-xl">
            <div className="row align-items-end mb-2">
              <div className="col-md-6">
                <p className="fw-medium mb-2">Aprendendo</p>
                <h5 className="fw-medium">
                  Exibindo {allCourses.length} de {totalCourses} conteúdos
                </h5>
              </div>

              <div className="col-md-6">
                <div className="courses-sort align-items-center">
                  <label
                    className={`btn btn-md btn-icon mb-1 mb-md-0 me-1 ${listType === 'grid' ? 'btn-primary bg-blue border-blue' : 'btn-light border-dark'}`}
                    style={{ width: "3.625rem", height: "3.125rem" }}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autocomplete="off"
                      checked={listType === 'grid'}
                      onChange={(e) => setListType(e.target.checked ? 'grid' : 'row')}
                    ></input>
                    <i className="fe fe-grid" style={{ fontSize: "1.2rem" }} />
                  </label>


                  <label
                    className={`btn btn-icon mb-1 mb-md-0 me-2 ${listType === 'row' ? 'btn-primary bg-blue border-blue' : 'btn-light border-dark'}`}
                    style={{
                      width: "3.625rem",
                      height: "3.125rem",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autocomplete="off"
                      checked={listType === 'row'}
                      onChange={(e) => setListType(e.target.checked ? 'row' : 'grid')}
                    ></input>
                    <i
                      className="fe fe-align-justify"
                      style={{ fontSize: "1.85rem" }}
                    />
                  </label>

                  <div className="d-inline-block">
                    <select onChange={handleChangeOrder} className="form-select" aria-label="Ordenar Por">
                      <option value="" selected>
                        Ordenar Por
                      </option>
                      <option value={['title', 'ASC']}>Título</option>
                      <option value={['createdAt', 'DESC']}>
                        Data de Inclusão
                      </option>
                      <option value={['difficulty', 'DESC']}>
                        Nível de Habilidade
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-xl">
            <div className="row mb-12">
              <div className="col-md filters-col">
                <div className="py-2">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="mb-0">Filtro</h4>
                    </div>

                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="ls-md text-uppercase mt-3 fs-7">
                          Categoria
                        </p>

                        {categoriesNames.map(({ id, name }) => (
                          <div className="form-check mb-1">
                            <input
                              checked={
                                filters.categories &&
                                filters.categories.includes(id)
                              }
                              onChange={handleChangeCategoryFilter(id)}
                              className="form-check-input"
                              type="checkbox"
                              value={id}
                              id={name}
                            />
                            <label
                              className="form-check-label fw-medium"
                              htmlFor={name}
                            >
                              {name}
                            </label>
                          </div>
                        ))}
                      </li>

                      <li className="list-group-item">
                        <p className="ls-md text-uppercase mt-3 fs-7">
                          Classificações
                        </p>

                        {filters.stars.map((star) => (
                          <div className="form-check mb-1">
                            <input
                              onClick={handleChangeRatingFilter}
                              checked={filter.rating && filter.rating === star}
                              value={star}
                              className="form-check-input"
                              type="radio"
                              name="stars"
                              id="star1"
                            />
                            <label
                              className="form-check-label fw-medium"
                              htmlFor="star1"
                            >
                              <span>
                                {Array(5)
                                  .fill(null)
                                  .map((val, index) => (
                                    <Star
                                      key={index}
                                      {...getStarProps(index, star)}
                                    />
                                  ))}
                              </span>
                              <span className="fs-6 text-muted">
                                {star} e acima
                              </span>
                            </label>
                          </div>
                        ))}
                      </li>

                      <li className="list-group-item">
                        <p className="ls-md text-uppercase mt-3 fs-7">
                          Público Alvo
                        </p>

                        {groupsNames.map(({ id, name }) => (
                          <div className="form-check mb-1">
                            <input
                              checked={
                                filters.groups && filters.groups.includes(id)
                              }
                              onChange={handleChangeGroupFilter(id)}
                              className="form-check-input"
                              type="checkbox"
                              value={id}
                              id={name}
                            />
                            <label
                              className="form-check-label fw-medium"
                              htmlFor={name}
                            >
                              {name}
                            </label>
                          </div>
                        ))}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className={`courses-list ${listType === 'row' && !isMiddle ? 'flex-column' : ''}`}>
                  {
                    allCourses.map((course) => (
                      course && (
                        <div key={course.id} className="courses-list_item" style={listType === 'row' && !isMiddle ? { maxHeight: 240 } : {}}>
                          <CardComponent
                            title={course.title}
                            is_marked={course.is_marked}
                            image={course.thumbnail_url || ImageItem6}
                            subscribers={course.ratings}
                            stars={course.ratingAverage}
                            difficulty={course.difficulty}
                            profile={{
                              name: `${course.author && course.author.firstname || ''} ${course.author && course.author.surname || ''}`,
                              avatar: course.author && course.author.profile_picture || '',
                            }}
                            link={`/curso/${course.id}`}
                            onCheck={
                              () => {
                                course.is_marked
                                  ? unmarkCourse({ courseId: course.id })
                                  : markCourse({ courseId: course.id })
                              }
                            }
                            Components={{ Footer: CardFooter }}
                          />
                        </div>
                      )
                    ))
                  }
                  <span></span>
                  <div className="loading-content">
                    <LoadingSpinner show={isFetching} />
                    <h2
                      style={{
                        display: allCourses.length == 0 && !isFetching ? "block" : "none",
                        marginTop: "20px",
                      }}
                    >
                      Nenhum curso foi encontrado.
                    </h2>
                  </div>

                  {!isFetching &&
                    allCourses.length != totalCourses &&
                    totalCourses > 0 && (
                      <div ref={ref} style={{ height: 10, width: "100%" }} />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </PageContent>
    </PageWrapper>
  );
};
