import Avatar1 from '../../assets/images/avatar/avatar-1.jpg';
import Avatar2 from '../../assets/images/avatar/avatar-2.jpg';
import Avatar3 from '../../assets/images/avatar/avatar-3.jpg';
import Default from '../../assets/images/avatar/default.jpg';

import { SearchBar } from '../search-bar';
import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import Pusher from 'pusher-js';
import { API } from '../../clients/api';
import { useGlobal } from '../../contexts';
import moment from "moment";
import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import cloneDeep from 'lodash/cloneDeep'

export const Header = ({ onToggle = () => { }, toggle }) => {
  const queryClient = useQueryClient();
  const { token, user, signout } = useGlobal();
  const [alerts, setAlerts] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const searchRef = useRef();
  const mutation = useMutation(async (status) => await API.changeStatus(token, status), {
    onMutate: async status => {
      await queryClient.cancelQueries(['users', 'me', { token }])
      const previous = queryClient.getQueryData(['users', 'me', { token }])
      queryClient.setQueryData(['users', 'me', { token }], old => ({ ...old, status }))
      return { previous }
    },
    onError: (err, status, context) => {
      queryClient.setQueryData(['users', 'me', { token }], context.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries(['users', 'me', { token }])
    },
  })

  const { mutate: changeNotificationConfig } = useMutation(async (disable_notifications) => await API.updateUserInfo(token, { disable_notifications }), {
    onMutate: async disable_notifications => {
      await queryClient.cancelQueries(['users', 'me', { token }])
      const previous = queryClient.getQueryData(['users', 'me', { token }])
      queryClient.setQueryData(['users', 'me', { token }], old => ({ ...old, disable_notifications }))
      return { previous }
    },
    onError: (err, disable_notifications, context) => {
      queryClient.setQueryData(['users', 'me', { token }], context.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries(['users', 'me', { token }])
    },
  })

  useEffect(() => {
    const keyFn = (e) => {

      if (e.keyCode == 13) {
        e.preventDefault();
        searchRef.current.focus();
      }
    }

    document.addEventListener('keydown', keyFn)

    return () => document.removeEventListener('keydown', keyFn)
  })

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER
    });

    const global = pusher.subscribe('global');

    global.bind('novo-curso', data => {
      setAlerts(old => [{
        Notification: data
      }, ...old]);
    });

    const course = pusher.subscribe('cursos');

    course.bind('novo-comentário', data => {
      setAlerts(old => [{
        Notification: data
      }, ...old]);
    });

  }, [])

  useEffect(() => {
    (async () => {
      const { data = [] } = await API.getAlerts(token);

      if (Array.isArray(data)) {
        setAlerts(old => [...old, ...data]);
      }
    })()
  }, [])

  const readAlert = (id) => () => {
    const cloned = cloneDeep(alerts);

    if (id) {
      setAlerts(
        produce(draft => {
          const alert = draft.find(({ Notification }) => Notification.uuid === id);

          alert.is_read = true;
        })
      );

      API.readAlert(token, { notifications: [id], is_read: true }).catch(() => {
        setAlerts(cloned)
      })
    }
  }

  const removeAlert = (id) => () => {
    if (id) {
      API.removeAlert(token, [id]).then(() => {
        setAlerts(
          produce(draft => {
            const index = draft.findIndex(({ Notification }) => Notification.uuid === id);

            draft.splice(index, 1);
          })
        )
      })
    }
  }

  const hasUnreadAlerts = alerts.find(({ is_ready }) => !is_ready);

  const getDiff = (createdAt) => {
    const minutes = moment().diff(moment(createdAt), 'minutes');
    const hours = moment().diff(moment(createdAt), 'hours');
    const days = moment().diff(moment(createdAt), 'days');


    if (Math.floor(hours / 72) >= 1) {
      return `${days} d`
    } else if (Math.floor(minutes / 120) >= 1) {
      return `${hours} h`
    } else {
      return `${minutes} m`
    }
  }

  return (
    <div className="header">
      <nav className="navbar-default navbar navbar-expand-lg" style={{ position: 'relative' }}>
        <a id="nav-toggle" href="#" onClick={onToggle} style={{ position: 'absolute', color: '#1B4298', left: 20, top: '50%', transform: 'translateY(-50%)' }}>
          <i className="fe fe-menu"></i>
        </a>

        <div className='container-xl'>
          <div className="d-none d-md-none d-lg-block search-item">
            <SearchBar ref={searchRef} />
          </div>

          <ul className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
            <li className="dropdown stopevent">
              <a onClick={(e) => setShowNotifications(!showNotifications)} className={`btn btn-light btn-icon rounded-circle text-muted ${hasUnreadAlerts ? 'indicator indicator-primary' : ''}`} href="#" role="button" id="dropdownNotification" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fe fe-bell"></i>
              </a>
              <div className={`dropdown-menu dropdown-menu-end dropdown-menu-lg ${showNotifications ? 'show' : ''}`} aria-labelledby="dropdownNotification">
                <div className=" ">
                  <div className="border-bottom px-3 pb-3 d-flex justify-content-between align-items-center">
                    <span className="h4 mb-0">Notificações</span>
                    <div className="form-check">
                      <input
                        onChange={(e) => changeNotificationConfig(e.target.checked)}
                        checked={user && user.disable_notifications}
                        className="form-check-input"
                        type="checkbox"
                        id="notifications"
                      />
                      <label className="form-check-label" htmlFor="notifications">
                        Desabilitar Notificações
                      </label>
                    </div>
                  </div>
                  <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '300px' }}>
                    <ul className="list-group list-group-flush notification-list-scroll" style={{ overflow: 'auto', width: 'auto', height: '300px' }}>
                      {
                        alerts && alerts.map(({ Notification: { text, createdAt, uuid } = {}, User: { firstname, surname, profile_picture } = {}, is_read }, index) => (
                          <li key={`${uuid}-${index}`} className="list-group-item bg-light">
                            <div className="row">
                              <div className="col">
                                <a className="text-body" href="#">
                                  <div className="d-flex">
                                    {
                                      profile_picture && (
                                        <img src={profile_picture} alt="" className="avatar-md rounded-circle" />
                                      )
                                    }

                                    <div className="ms-3">
                                      {
                                        firstname && (
                                          <h5 className="fw-bold mb-1">{`${firstname} ${surname}`}</h5>
                                        )
                                      }
                                      <p className="mb-3">
                                        {text}
                                      </p>
                                      <span className="fs-6 text-muted">
                                        <span><span className="fe fe-thumbs-up text-success me-1"></span>{getDiff(createdAt)}</span>
                                        <span className="ms-1">{new Date(createdAt).toLocaleString()}</span>
                                      </span>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="col-auto text-center me-2">
                                <a onClick={readAlert(uuid)} className={`badge-dot cursor-pointer ${is_read ? 'bg-success' : 'bg-info'}`} data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Mark as read">
                                </a>
                                <div className='cursor-pointer'>
                                  <a onClick={removeAlert(uuid)} className="bg-transparent" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Remove">
                                    <i className="fe fe-x text-muted"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  <div className="border-top px-3 pt-3 pb-0">
                    <Link to="/notificacoes" className="text-link fw-semi-bold">
                      Ver Todas
                    </Link>
                  </div>
                </div>
              </div>
            </li>

            <li className="dropdown ms-4">
              <a onClick={(e) => setShowProfile(!showProfile)} className="rounded-circle" href="#" role="button" id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                <div className={`avatar avatar-md avatar-indicators ${user && user.status === 'offline' ? 'avatar-offline' : 'avatar-online'}`}>
                  <img alt="avatar" src={user && user.profile_picture || Default} className="rounded-circle" />
                </div>
              </a>
              <div className={`dropdown-menu dropdown-menu-end dropdown-menu-lg ${showProfile ? 'show' : ''}`} aria-labelledby="dropdownUser">
                <div className="dropdown-item">
                  <div className="d-flex">
                    <div onClick={() => mutation.mutate(user && user.status === 'offline' ? 'online' : 'offline')} className={`cursor-pointer avatar avatar-md avatar-indicators ${user && user.status === 'offline' ? 'avatar-offline' : 'avatar-online'}`}>
                      <img alt="avatar" src={user && user.profile_picture || Default} className="rounded-circle" />
                    </div>
                    <div className="ms-3 lh-1">
                      <h5 className="mb-1">{`${user ? user.firstname : ''} ${user ? user.surname : ''}`}</h5>
                      <p className="mb-0 text-muted">{user ? user.email : ''}</p>
                    </div>
                  </div>
                </div>
                <div className="dropdown-divider"></div>
                <ul className="list-unstyled">
                  <li>
                    <Link className="dropdown-item" to="/perfil">
                      <i className="fe fe-user me-2"></i> Perfil
                    </Link>
                  </li>
                </ul>
                <div className="dropdown-divider"></div>
                <ul className="list-unstyled">
                  <li>
                    <a className="dropdown-item" onClick={() => signout()}>
                      <i className="fe fe-power me-2"></i> Sair
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}				