export const Footer = () => {

  return (
    <div className="footer-container container-xl">
      <div className="footer-content">
        <span className="me-auto">
          © 2021 ORGANON - Todos os direitos reservados.
        </span>

        <span>
          Privacidade
        </span>

        <span>
          Termos de uso
        </span>

        <span>
          Desenvolvido por: <a className="text-decoration-underline text-blue" href='#' style={{ color: '#1492e6' }}>RSTcom</a>
        </span>
      </div>
    </div>
  )
}