import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchCourseById,
  fetchCourseRatings,
  updateMarkedCourseBookMark,
  updateUnmarkedCourseBookMark,
} from "../../redux/thunx/courses.thunx";
import {
  Tab,
  Nav,
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import { useGlobal } from "../../contexts";
import {
  Navbar,
  Header,
  Footer,
  PageWrapper,
  PageContent,
  Star,
  Card,
  SearchBar,
  LoadingSpinner,
} from "../../components";
import { courseActions } from "../../redux/store/courses";
import ImageCourse from "../../assets/images/organon/course-image.png";
import { getStarProps } from "../../helpers";
import ReactQuill from 'react-quill';
import { Modal, Button } from 'react-bootstrap';
import moment from "moment";
import { API } from '../../clients/api';

import ImageItem1 from "../../assets/images/organon/item-1.png";

import profilePic1 from "../../assets/images/avatar/avatar-1.jpg";
import profilePic2 from "../../assets/images/avatar/avatar-2.jpg";
import profilePic3 from "../../assets/images/avatar/avatar-3.jpg";
import profilePic5 from "../../assets/images/avatar/avatar-5.jpg";
import defaultAvatar from "../../assets/images/avatar/default.jpg";
import { toast } from 'react-toastify';
import { number } from "prop-types";

const BASE_URL = process.env.REACT_APP_BASE_API;

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <h3 className="mb-0 fw-medium fs-4" onClick={decoratedOnClick}>
      <a
        style={{ color: "#635E7C" }}
        href="#"
        className="d-flex align-items-center text-decoration-none"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        <span className="me-auto">{children}</span>
        <span
          className="collapse-toggle ms-4"
          style={isCurrentEventKey ? { transform: "rotate(0deg)" } : {}}
        >
          <i className="fe fe-chevron-up"></i>
        </span>
      </a>
    </h3>
  );
}

const levels = {
  beignners: {
    label: 'Iniciante',
    rating: 1
  },
  intermediate: {
    label: 'Intermediário',
    rating: 2
  },
  advanced: {
    label: 'Avançado',
    rating: 3
  }
}

export const Curso = () => {
  const navigate = useNavigate();
  const [certified, setCertified] = useState('');
  const [isLoadingCertified, setIsLoadingCertified] = useState(false);
  const [conclusionPercentage, setConclusionPercentage] = useState(0);
  const course = useSelector((state) => state.course.currentCourse);
  const courseRating = useSelector((state) => state.course.ratings);
  const loading = useSelector((state) => state.course.loading);
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const { isToggled, handleToggle, token } = useGlobal();
  const [currentTab, setCurrentTab] = useState("content");
  const [isRatingLoading, setIsRatingLoading] = useState(false);
  const [isModalRatingOpen, setIsModalRatingOpen] = useState(false);
  const [rating, setRating] = useState({ comment: '' });
  console.log({ courseRating })
  const handleCloseModalRating = () => {
    setRating({ comment: '' });
    setIsModalRatingOpen(false)
  };
  const handleOpenModalRating = () => setIsModalRatingOpen(true)

  const handleConfirmModalRating = async () => {
    setIsRatingLoading(true);
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    fetch(
      `${BASE_URL}/auth/users/courses/${courseId}/ratings`,
      {
        headers,
        method: 'POST',
        body: JSON.stringify(rating)
      }
    ).then(async (response) => {
      if (response) {
        const { data } = await response.json();

        if (data && typeof data === 'object') {
          handleCloseModalRating();
          toast.success('Sucesso ao cadastrar avaliação!');
        } else {
          toast.error('Falha ao cadastrar avaliação!');
        }

        setIsRatingLoading(false);
      }
    })
  }

  function turnLoadingOn() {
    dispatch(courseActions.turnLoadingOn());
  }

  function turnLoadingOff() {
    dispatch(courseActions.turnLoadingOff());
  }

  function turnLoadingOffIfCourseIsLoaded() {
    if (course && course.hasOwnProperty("id")) {
      turnLoadingOff();
    }
  }

  function fetchCurrentCourse() {
    turnLoadingOn();
    dispatch(fetchCourseById({ token, courseId }));
  }

  function loadRatings() {
    turnLoadingOn();
    dispatch(fetchCourseRatings({ token, courseId }));
  }

  function toggleBookMark() {
    if (!course.is_marked) {
      dispatch(updateMarkedCourseBookMark({ token, courseId }));
    } else {
      dispatch(updateUnmarkedCourseBookMark({ token, courseId }));
    }

  }

  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  const Stars = () => {
    const totalStars = Array(5)
      .fill(null)
      .map((val, index) => (
        <Star
          key={index}
          {...getStarProps(index, parseFloat(course.ratingAverage))}
        />
      ));
    return totalStars;
  };

  async function handleStartCourse() {
    if (course && course.content && course.content.length > 0) {
      const [group = {}] = course.content || [];

      if (group && group.lessons && group.lessons.length) {
        const [lesson = {}] = group.lessons || [];

        Promise.all([
          API.vinculateCourse(token, Number(courseId), {}),
          API.vinculateLesson(token, lesson.id, { time_stopped: 0, is_concluded: false })
        ]).then(() => {
          navigate(`/curso/${courseId}/aula/${lesson.id}`);
        }).catch(() => {
          toast.error('Falha ao iniciar curso');
        })
      } else {
        toast.info('Curso não possui aulas cadastradas');
      }
    } else {
      toast.info('Curso não possui aulas cadastradas');
    }
  }

  const handleDownloadCertified = async () => {
    setIsLoadingCertified(true);
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/files/certificate/zmMppIh59FnQlFsih1Vg9WB0n8M9QW2.pdf`, {
      headers,
      method: 'GET',
    });

    const doc = await response.blob()
    const reader = new FileReader();

    reader.addEventListener('load', function () {
      const link = document.createElement("a");
      link.download = course.title || 'Certificado';
      link.href = reader.result;
      link.click();

      link.remove();
      setIsLoadingCertified(false)
    })

    if (doc) {
      reader.readAsDataURL(doc);
    }
  }

  function handleClick() {
    toggleBookMark();
  }

  useEffect(() => {
    if (courseId) {
      API.getConclusionPercentage(token, Number(courseId))
        .then(({ data }) => setConclusionPercentage(data))
    }
  }, [courseId])

  console.log({ conclusionPercentage })

  useEffect(() => {
    fetchCurrentCourse();
  }, [courseId]);

  useEffect(() => {
    loadRatings();
  }, [isCurrentTab("avaliations"), courseId]);

  useEffect(() => {
    turnLoadingOffIfCourseIsLoaded();
  }, [course, courseRating]);

  useEffect(() => {
    if (courseId) {
      (async () => {
        const { data, ok } = await API.getCertificate(token, courseId);

        if (ok) {
          setCertified(data);
        }
      })()
    }
  }, [courseId])

  const level = course && course.difficulty && levels.hasOwnProperty(course.difficulty) ? levels[course.difficulty] : levels.beignners;

  console.log({ course })
  return (
    <PageWrapper className={isToggled ? "toggled" : ""}>
      <Navbar />

      <PageContent>
        <Header onToggle={handleToggle}>header</Header>

        <div className="course" style={{ position: "relative" }}>
          <div
            className="course-banner"
            style={{ position: "absolute", top: 0, width: "100%", zIndex: -1 }}
          ></div>

          <div
            className="container-xl"
            style={loading ? { minHeight: "100vh" } : {}}
          >
            {loading || !course || !course.id ? (
              <LoadingSpinner show />
            ) : (
              <>
                <div className="row course-intro">
                  <div className="col-xl-8 mb-4 left-column">
                    <div className="left-column_title">
                      <h1 className="text-white display-4 fw-bold">
                        {course.title}
                      </h1>

                      <p
                        className="left-column_title-description text-white fw-medium fs-3 mb-5"
                        dangerouslySetInnerHTML={{
                          __html: course.description.substr(0, 100),
                        }}
                      />

                      <div className="fw-medium left-column_title-stats">
                        <span>
                          <a onClick={handleClick} className="text-muted">
                            <i
                              className="material-icons  mark-button"
                              style={{
                                fontSize: "1rem",
                                verticalAlign: "middle",
                                color: course.is_marked ? "#FFFFFF" : "",
                              }}
                            >
                              {course.is_marked
                                ? "bookmark"
                                : "bookmark_border"}
                            </i>
                          </a>
                          Marcar
                        </span>

                        <span>
                          <i className="me-1 material-icons">group</i>
                          {`${course.subscribers} Inscritos`}
                        </span>

                        {course.is_rating_enabled && (
                          <span>
                            <span style={{ lineHeight: 1 }}>
                              <Stars />
                            </span>
                            ({course.ratings})
                          </span>
                        )}

                        <span>
                          <svg
                            style={{ lineHeight: 1 }}
                            className="me-1 mt-n1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="3" y="8" width="2" height="6" rx="1" fill={level.rating >= 1 ? "#754FFE" : "#DBD8E9"} />
                            <rect x="7" y="5" width="2" height="9" rx="1" fill={level.rating >= 2 ? "#754FFE" : "#DBD8E9"} />
                            <rect x="11" y="2" width="2" height="12" rx="1" fill={level.rating >= 3 ? "#754FFE" : "#DBD8E9"} />
                          </svg>
                          {level.label}
                        </span>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <Nav
                          onSelect={handleSelectTab}
                          id="course-intro-tab"
                          variant="pills"
                          className="nav-line-bottom border-bottom-0 mb-4"
                        >
                          <Nav.Item>
                            <Nav.Link
                              active={isCurrentTab("content")}
                              eventKey="content"
                            >
                              Conteúdo
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              active={isCurrentTab("description")}
                              eventKey="description"
                            >
                              Descrição
                            </Nav.Link>
                          </Nav.Item>
                          {course.is_rating_enabled && (
                            <Nav.Item>
                              <Nav.Link
                                active={isCurrentTab("avaliations")}
                                eventKey="avaliations"
                              >
                                Avaliações
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>
                      </div>
                      <div className="card-body p-0">
                        <Tab.Content>
                          <Tab.Pane
                            className="panel"
                            active={isCurrentTab("content")}
                          >
                            <Accordion defaultActiveKey="0">
                              <ul className="list-group list-group-flush">
                                {course.content &&
                                  course.content.map(
                                    ({ id, name, lessons = [] }) => (
                                      <li key={id} className="list-group-item">
                                        <Accordion.Item
                                          className="border-0"
                                          eventKey={id}
                                        >
                                          <CustomToggle eventKey={id}>
                                            {name}
                                          </CustomToggle>
                                          <Accordion.Body eventKey={id}>
                                            {lessons.map(({ title }) => (
                                              <div className="panel">
                                                {title}
                                              </div>
                                            ))}
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </Accordion>
                          </Tab.Pane>
                          <Tab.Pane
                            className="panel"
                            active={isCurrentTab("description")}
                          >
                            <span dangerouslySetInnerHTML={{ __html: course.description }} />
                          </Tab.Pane>
                          <Tab.Pane
                            className="panel ratings-featured"
                            active={isCurrentTab("avaliations")}
                          >
                            <h4 className="mb-6 fs-6-5 text-dark fw-bold">
                              Como os alunos avaliaram esse curso
                            </h4>

                            <div className="d-flex mb-5">
                              <div className="text-center" style={{ flex: 4 }}>
                                <p className="display-2 text-dark">
                                  {courseRating && courseRating.ratingsAverage && courseRating.ratingsAverage.replace(
                                    ".",
                                    ","
                                  )}
                                </p>

                                <p className="mb-0">
                                  <span style={{ lineHeight: 1 }}>
                                    {Array(5)
                                      .fill(null)
                                      .map((val, index) => (
                                        <Star
                                          key={index}
                                          {...getStarProps(
                                            index,
                                            parseFloat(
                                              courseRating.ratingsAverage
                                            )
                                          )}
                                        />
                                      ))}
                                  </span>
                                </p>

                                <p className="mb-0">
                                  <small>
                                    (Baseado em {courseRating && courseRating.ratings ? courseRating.ratings.length : 0}{" "}
                                    comentários)
                                  </small>
                                </p>
                              </div>
                              <ul className="list-ranking">
                                {courseRating &&
                                  courseRating.ratingsRanked &&
                                  courseRating.ratingsRanked.map(
                                    (ratingStar) => {
                                      const percentage =
                                        parseFloat(
                                          ratingStar.total_ratings /
                                          courseRating.ratings.length
                                        ) * 100;
                                      return (
                                        <li
                                          className="d-flex align-items-center mb-2"
                                          style={{ lineHeight: 1 }}
                                        >
                                          {Array(5)
                                            .fill(null)
                                            .map((val, index) => (
                                              <Star
                                                key={index}
                                                {...getStarProps(
                                                  index,
                                                  parseFloat(ratingStar.rating)
                                                )}
                                              />
                                            ))}
                                          <span className="ms-2">
                                            {percentage.toFixed()}%
                                          </span>

                                          <div
                                            style={{
                                              width: "2rem",
                                              height: "0.4rem",
                                            }}
                                            className="progress ms-auto"
                                          >
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: `${percentage.toFixed()}%`,
                                                background: "#FFAA46",
                                              }}
                                              aria-valuenow={percentage.toFixed()}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>

                            </div>
                            <hr></hr>

                            <h4 className="mb-3 fs-6-5 text-dark fw-bold">
                              Avaliações
                            </h4>

                            <div className="mb-5">
                              <SearchBar placeholder="Revisão de pesquisa" />
                            </div>
                            {
                              courseRating &&
                              courseRating.ratings &&
                              courseRating.ratings.map((rating) => {
                                return (
                                  <div className="my-3">
                                    <div className="d-flex">
                                      <a
                                        className="rounded-circle d-flex align-items-center gap-3"
                                        href="#"
                                        role="button"
                                      >
                                        <div className="avatar avatar-lg me-2">
                                          <img
                                            alt="avatar"
                                            src={
                                              rating.User.profile_picture ||
                                              defaultAvatar
                                            }
                                            className="rounded-circle"
                                          />
                                        </div>

                                        <div
                                          className=""
                                          style={{ lineHeight: "1" }}
                                        >
                                          <p className="mb-2">
                                            <span className="fw-bold">
                                              {`${rating.User.firstname} ${rating.User.surname}`}
                                            </span>
                                            <span className="ms-2">
                                              <small>
                                                {moment(rating.createdAt)
                                                  .locale("pt-br")
                                                  .fromNow()}
                                              </small>
                                            </span>
                                          </p>

                                          <p className="mb-0">
                                            <span style={{ lineHeight: 1 }}>
                                              {Array(5)
                                                .fill(null)
                                                .map((val, index) => (
                                                  <Star
                                                    key={index}
                                                    {...getStarProps(
                                                      index,
                                                      parseFloat(rating.rating)
                                                    )}
                                                  />
                                                ))}
                                            </span>
                                          </p>
                                        </div>
                                      </a>
                                    </div>

                                    <div style={{ paddingLeft: "5rem" }}>
                                      <p className="mb-3" dangerouslySetInnerHTML={{ __html: rating.comment }} />

                                      <div>
                                        <p>Esta avaliação foi útil?</p>

                                        <div>
                                          <button className="btn btn-xs btn-primary">
                                            Sim
                                          </button>

                                          <button className="btn btn-xs btn-outline-secondary ms-2">
                                            Não
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            {
                              !courseRating.rated && (
                                <div className="mt-3">
                                  <Button onClick={handleOpenModalRating}>Avaliar</Button>
                                </div>
                              )
                            }

                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                      <div className="card-body p-3"></div>
                    </div>
                  </div>

                  <div className="col-xl-4 right-column">
                    <div className="card">
                      <div style={{ maxHeight: "200px", position: "relative" }}>
                        <a
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          className="popup-youtube icon-shape rounded-circle btn-play icon-xl text-decoration-none"
                          onClick={handleStartCourse}
                        >
                          <i className="fe fe-play"></i>
                        </a>

                        <img
                          src={ImageCourse}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          className="card-img-top border border-4 border-white"
                          alt=""
                        />
                      </div>

                      <div className="card-body">
                        <a
                          onClick={handleStartCourse}
                          className="btn btn-primary text-uppercase w-100 bg-blue border-blue"
                        >
                          {
                            conclusionPercentage === 100
                              ? 'Assisir Novamente'
                              : course.is_started
                                ? 'Continuar'
                                : 'Começar'
                          }
                        </a>

                        {
                          certified && (
                            <button
                              onClick={handleDownloadCertified}
                              className="btn btn-primary text-uppercase w-100 bg-blue border-blue mt-2"
                              disabled={isLoadingCertified}
                            >
                              Certificado
                            </button>
                          )
                        }
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h4 className="mb-0 text-dark">O que está incluído</h4>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li
                          style={{
                            display: Number(course.duration) ? "block" : "none",
                          }}
                          className="list-group-item"
                        >
                          <span>
                            <i
                              className="fe fe-play-circle me-2"
                              style={{ color: "#754FFE" }}
                            ></i>
                            {
                              Math.floor(course.duration / 3600)
                                ? `${Math.floor(course.duration / 3600)} horas de vídeo`
                                : `${Math.floor((course.duration % 3600) / 60)} minutos de vídeo`
                            }
                          </span>
                        </li>
                        <li
                          style={{
                            display: course.certificate_uuid ? "block" : "none",
                          }}
                          className="list-group-item"
                        >
                          <span
                            className="material-icons me-2"
                            style={{ color: "#19CB98" }}
                          >
                            workspace_premium
                          </span>
                          Certificado
                        </li>
                        <li
                          style={{
                            display: course.total_article ? "block" : "none",
                          }}
                          className="list-group-item"
                        >
                          <span>
                            <i
                              className="fe fe-calendar me-2"
                              style={{ color: "#1492E6" }}
                            ></i>
                            {course.total_articles} artigos
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span>
                            <i
                              className="fe fe-video me-2"
                              style={{ color: "#ADA8BD" }}
                            ></i>
                            Assistir Offline
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span>
                            <i
                              className="fe fe-clock me-2"
                              style={{ color: "#FFAA46" }}
                            ></i>
                            Acesso vitalício
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row related-section">
                  <div className="col-12">
                    <div>
                      <h2 className="text-black display-6 mb-4">
                        Relacionados
                      </h2>
                      <div className="related-list">
                        {course.related_courses &&
                          course.related_courses.length ? (
                          course.related_courses.map(
                            ({
                              id,
                              title,
                              difficulty,
                              thumbnail,
                              author,
                              is_rating_enabled,
                              is_marked,
                              ratingAverage,
                              ratings
                            }) => (
                              <div key={id} className="related-card">
                                <Card
                                  id={id}
                                  title={title}
                                  is_marked={is_marked}
                                  subscribers={ratings}
                                  image={thumbnail || ImageItem1}
                                  stars={is_rating_enabled ? ratingAverage : -1}
                                  difficulty={difficulty}
                                  profile={{
                                    name: `${author.firstname} ${author.surname}`,
                                    avatar:
                                      author.profile_picture || defaultAvatar,
                                  }}
                                  link={`/curso/${id}`}
                                />
                              </div>
                            )
                          )
                        ) : (
                          <p>Não há cursos relacionados</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <Footer />
      </PageContent>
      <ModalRatings
        isOpen={isModalRatingOpen}
        handleClose={handleCloseModalRating}
        handleConfirm={handleConfirmModalRating}
        rating={rating}
        setRating={setRating}
        isLoading={isRatingLoading}
      />
    </PageWrapper>
  );
};

const ModalRatings = ({ isOpen, rating, setRating, isLoading, handleConfirm, handleClose }) => {

  return (
    <Modal size='md' show={isOpen}>
      <Modal.Header>
        <Modal.Title>Avaliação</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='d-flex flex-column'>
          <div className="mb-3">
            <select
              value={rating.rating}
              onChange={(e) => setRating((old) => ({ ...old, rating: Number(e.target.value) }))}
              className="form-select"
              aria-label="Avaliação"
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Comentário</label>
            <ReactQuill
              theme="snow"
              value={rating.comment}
              onChange={(comment) => setRating((old) => ({ ...old, comment }))}
            />
          </div>

        </div>
      </Modal.Body>

      <Modal.Footer>

        <Button onClick={handleClose} variant="secondary">
          Cancelar
        </Button>

        <Button disabled={isLoading} onClick={handleConfirm} variant="primary">
          Concluir
        </Button>
      </Modal.Footer>
    </Modal>
  )
}