import { Link } from "react-router-dom";
import { useGlobal } from "../../contexts";
import { NavlinkCollapse } from "../../components";
import Logo from "../../assets/images/organon/logo.png";
import { useQuery } from "react-query";
import { API } from "../../clients/api";
import { LoadingSpinner } from "../loading-spinner";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export const Navbar = () => {
  const { token, signout } = useGlobal();

  const { data: menu } = useQuery(
    "menu",
    async () => {
      const { data = [] } = await API.fetchMenulist(token);

      return data;
    },
    {
      initialData: null,
    }
  );

  return (
    <nav className="navbar-vertical navbar bg-blue">
      <Link to="/home" className="navbar-brand">
        <img src={Logo} alt="Logo Organon" />
      </Link>

      <ul className="navbar-nav flex-column">
        {!menu ? (
          <LoadingSpinner show={true} />
        ) : (
          [...menu].sort(sortFn).map((menu) => (
            <>
              <OverlayTrigger
                key='right'
                placement='right'
                overlay={
                  !menu.isSectionPermitted ? (
                    <Tooltip id='tooltip-right'>
                      Em Breve
                    </Tooltip>
                  ) : <></>
                }
              >
                <li className="nav-item">
                  {menu.items.length ? (
                    <NavlinkCollapse
                      id={`nav${menu.section}`}
                      title={menu.section}
                      disabled={!menu.isSectionPermitted}
                      hidden={!menu.visible}
                      icon={
                        menu.icon ? (
                          menu.icon.type === "material" ? (
                            <span className="nav-icon me-2 material-icons">
                              {menu.icon.name}
                            </span>
                          ) : (
                            <i className={`nav-icon me-2 fe ${menu.icon.name}`} />
                          )
                        ) : null
                      }
                    >
                      {[...menu.items].sort(sortFn).map(({ label, path = "/" }) => (
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <Link className="nav-link" to={path}>
                              {label}
                            </Link>
                          </li>
                        </ul>
                      ))}
                    </NavlinkCollapse>
                  ) : (
                    <Link
                      className={`nav-link ${menu.isSectionPermitted ? "" : "disabled"} ${menu.visible ? "" : "hidden"}`}
                      to={menu.section !== 'Sair' && menu.path ? menu.path : '/'}
                      onClick={() => menu.section === 'Sair' ? signout() : ''}
                    >
                      {menu.icon.type === "material" ? (
                        <span className="nav-icon me-2 material-icons">
                          {menu.icon.name}
                        </span>
                      ) : (
                        <i className={`nav-icon me-2 fe ${menu.icon.name}`} />
                      )}
                      {menu.section}
                    </Link>
                  )}
                </li>
              </OverlayTrigger>
            </>
          ))
        )}
      </ul>
    </nav>
  );
};

const sortFn = (a, b) => {
  if (a.order > b.order) {
    return 1;
  }
  if (a.order < b.order) {
    return -1;
  }

  return 0;
}