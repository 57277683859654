import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../clients/api";

export const fetchCourseById = createAsyncThunk(
  "courses/fetchById",
  async ({ token, courseId }) => {
    const response = await API.fetchCourseById(token, courseId);

    return response.data;
  }
);

export const updateMarkedCourseBookMark = createAsyncThunk(
  "courses/markCourse",
  async ({ token, courseId }) => {
    const response = await API.markCourse(token, courseId);

    return response.data;
  }
);

export const updateUnmarkedCourseBookMark = createAsyncThunk(
  "courses/unmarkCourse",
  async ({ token, courseId }) => {
    const response = await API.unmarkCourse(token, courseId);

    return response.data;
  }
);

export const fetchCourses = createAsyncThunk(
  "courses/fetchCourses",
  async ({ token, courseId }) => {
    const response = await API.fetchCourses(token, courseId);

    return response.data;
  }
);

export const fetchCourseRatings = createAsyncThunk(
  "courses/fetchCourseRatings",
  async ({ token, courseId }) => {
    const response = await API.fetchCourseRatings(token, courseId);

    return typeof response.data === 'object' ? response.data : {};
  }
);
