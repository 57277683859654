import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCourseById,
  fetchCourses,
  updateMarkedCourseBookMark,
  updateUnmarkedCourseBookMark,
  fetchCourseRatings,
} from "../thunx/courses.thunx";

const initialState = {
  loading: true,
  list: [],
  currentCourse: {},
  ratings: {},
};

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCoursesList(state, action) {
      state.list = action.payload;
    },
    setCurrentCourse(state, action) {
      state.currentCourse = action.payload;
    },
    setRatings(state, action) {
      state.ratings = action.payload;
    },
    turnLoadingOn(state) {
      state.loading = true;
    },
    turnLoadingOff(state) {
      state.loading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        state.currentCourse = action.payload;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(updateMarkedCourseBookMark.fulfilled, (state, action) => {
        const index = state.currentCourse.related_courses.findIndex(
          (relatedCourse) => relatedCourse.id === action.payload.id
        );
        state.currentCourse.related_courses[index] = action.payload;
        state.currentCourse.is_marked = action.payload.is_marked;
      })
      .addCase(updateUnmarkedCourseBookMark.fulfilled, (state, action) => {
        const index = state.currentCourse.related_courses.findIndex(
          (relatedCourse) => relatedCourse.id === action.payload.id
        );

        state.currentCourse.related_courses[index] = action.payload;
        state.currentCourse.is_marked = action.payload.is_marked;
      })
      .addCase(fetchCourseRatings.fulfilled, (state, action) => {
        state.ratings = action.payload;
      });
  },
});

export const courseActions = courseSlice.actions;

export default courseSlice.reducer;
