const BASE_URL = process.env.REACT_APP_BASE_API;
const CLOUD_NAME = process.env.REACT_APP_CLOUD_NAME;
const CLOUD_KEY = process.env.REACT_APP_CLOUD_KEY;

export const API = {
  async fetchCategories(token, data = {}) {
    const queryParams = jsonToQueryParam(data);
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/categories/courses?${queryParams}`, {
      headers,
    });

    return response.json();
  },

  async fetchCategoriesNames(token, data = {}) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/categories`, {
      headers,
    });

    return response.json();
  },

  async fetchGroupsNames(token, data = {}) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/groups`, {
      headers,
    });

    return response.json();
  },

  async fetchCourseById(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/courses/${data}`, {
      headers,
    });

    return response.json();
  },

  async fetchCourses(token, data) {
    const queryParams = jsonToQueryParam(data);
    const headers = new Headers();
    headers.append("Authorization", token);
    const response = await fetch(`${BASE_URL}/auth/courses?${queryParams}`, {
      headers,
    });

    return response.json();
  },

  async fetchInProgressCourses(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/users/courses/in-progress`, {
      headers,
    });

    return response.json();
  },

  async fetchMarkedCourses(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/users/courses/marked`, {
      headers,
    });

    return response.json();
  },

  async fetchCourseRatings(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${BASE_URL}/auth/courses/${data}/ratings`, {
      headers,
    });

    return response.json();
  },

  async fetchLessonById(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/lessons/${data}`, {
      headers,
    });

    return response.json();
  },
  async markCourse(token, courseId) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(
      `${BASE_URL}/auth/users/courses/${courseId}/mark`,
      {
        method: "POST",
        headers,
      }
    );

    return response.json();
  },

  async unmarkCourse(token, courseId) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(
      `${BASE_URL}/auth/users/courses/${courseId}/mark`,
      {
        method: "DELETE",
        headers,
      }
    );

    return response.json();
  },

  //Navbar -
  async fetchMenulist(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/menu`, {
      headers,
    });

    return response.json();
  },

  async fetchUserInfo(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/users/me`, { headers });

    return response.json();
  },

  async updateUserInfo(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      body: JSON.stringify(data),
      headers: headers,
      method: "PUT",
    };

    const response = await fetch(`${BASE_URL}/auth/users`, requestOptions);

    return response.json();
  },

  async fetchTerms(token) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/terms`, { headers });

    return response.json();
  },

  async acceptTerms(token) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      body: JSON.stringify({ privacy_policy: true, accepted_terms: true }),
      headers,
      method: "PUT",
    };

    const response = await fetch(`${BASE_URL}/auth/users`, requestOptions);

    return response.json();
  },

  async changeStatus(token, status) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      body: JSON.stringify({ status }),
      headers,
      method: "PUT",
    };

    const response = await fetch(`${BASE_URL}/auth/users`, requestOptions);

    return response.json();
  },

  // Country - Fetch All
  async fetchCountry(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/countries`, { headers });

    return response.json();
  },

  async fetchStateByCountryId(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/states/${data}`, {
      headers,
    });

    return response.json();
  },

  async finishLesson(token, lessonId, data = {}) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${BASE_URL}/auth/users/lessons/${lessonId}`, {
      headers,
      body: JSON.stringify(data),
      method: "PUT",
    });

    return response.json();
  },

  async vinculateLesson(token, lessonId, data = {}) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${BASE_URL}/auth/users/lessons/${lessonId}`, {
      headers,
      body: JSON.stringify(data),
      method: "POST",
    });

    return response.json();
  },

  async vinculateCourse(token, courseId, data = {}) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${BASE_URL}/auth/users/courses/${courseId}`, {
      headers,
      body: JSON.stringify(data),
      method: "POST",
    });

    return response.json();
  },

  async getAlerts(token, data = {}) {
    const queryParams = jsonToQueryParam(data);
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/users/notifications?${queryParams}`, {
      headers
    });

    return response.json();
  },
  async readAlert(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(
      `${BASE_URL}/auth/users/notifications`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify(data)
      }
    );

    return response.json();
  },
  async removeAlert(token, notifications = []) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(
      `${BASE_URL}/auth/users/notifications`,
      {
        method: "DELETE",
        headers,
        body: JSON.stringify({ notifications })
      }
    );

    return response.json();
  },
  async getCertificate(token, courseId) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/courses/${courseId}/certificate`, {
      headers
    });

    return response.json();
  },

  async getConclusionPercentage(token, courseId) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/users/courses/${courseId}`, {
      headers
    });

    return response.json();
  },

  async getSignature(token, data) {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${BASE_URL}/auth/files/cloudinary-signature`, {
      headers,
      method: 'POST',
      body: JSON.stringify(data)
    });

    return response.json();
  },

  async uploadFile(token, file, { cloudKey = CLOUD_KEY, cloudName = CLOUD_NAME } = {}) {
    try {
      const url = `https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`;
      const result = await API.getSignature(token, { folder: 'users' });

      if (typeof result === 'object') {
        const { signature, timestamp } = result.data;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("api_key", cloudKey);
        formData.append("timestamp", timestamp);
        formData.append("signature", signature);
        formData.append("folder", 'users');

        const response = await fetch(url, {
          method: "POST",
          body: formData
        })

        return response.json();
      }
    } catch (error) {
      console.log(error);

      return error;
    }
  },

  async fetchBanners(token) {
    const headers = new Headers();
    headers.append("Authorization", token);

    const response = await fetch(`${BASE_URL}/auth/home/banners`, { headers });

    return response.json();
  },
};

const jsonToQueryParam = (params = {}) => {
  return Object.keys(params)
    .map((key) => {
      return (
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(
          Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key]
        )
      );
    })
    .join("&");
};
