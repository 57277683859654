import { LoadingSpinner } from "../../components";

const ButtonDefault = ({ onClickButton, btnTitle }) => (
  <button
    onClick={onClickButton}
    className="btn btn-primary btn-sm bg-blue border-blue px-8"
  >
    {btnTitle}
  </button>
);

export const ProfileHeader = (props) => {
  const {
    title,
    description,
    avatar,
    components: { Button = ButtonDefault } = {},
  } = props;

  return (
    <div className="profileHeader">
      <div className="profileHeader-banner"></div>

      <LoadingSpinner show={description == ""} />
      <div
        style={{ display: description != "" ? "flex" : "none" }}
        className="profileHeader-content"
      >
        <div className="profileHeader-avatar">
          <img
            src={avatar}
            className="rounded-circle avatar-xl border border-2 border-white"
            alt=""
          />
        </div>

        <div className="profileHeader-text">
          <p className="profileHeader-text_title display-6 mb-0">{title}</p>
          <p className="profileHeader-text_description fw-medium text-gray mb-0">
            <small>{description.toLowerCase()}</small>
          </p>
        </div>

        <div className="profileHeader-button">
          <Button {...props} />
        </div>
      </div>
    </div>
  );
};
