import { useRef } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { SignBanner, AccountCard } from '../../components';

export const ForgotPassword = () => {
  const loginRef = useRef(null);

  const handleForgotPassword = (e) => {
    e.preventDefault();

    toast.success('Um e-mail foi enviado para sua conta!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <div className='account-page-wrapper'>
      <SignBanner />

      <AccountCard>
        <p className='display-5 text-black'>Esqueci minha senha</p>

        <p className='fs-5 fw-bold text-muted mb-5'>Não tem uma conta? <Link to='/cadastro' className='ms-1'>Inscrever-se</Link></p>

        <form className='row' onSubmit={handleForgotPassword}>
          <div className="mb-4 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Nome de usuário ou email</label>
            <input ref={loginRef} type="email" id="lastname" className="form-control" placeholder="Email" required />
          </div>

          <div className="mb-5 col-12">
            <button className='btn btn-primary text-uppercase' style={{ width: '100%' }}>Recuperar</button>
          </div>
        </form>
      </AccountCard>
    </div>
  );
}