import { useEffect } from 'react';
import Slider from "react-slick";
import { useInfiniteQuery, useQueryClient, useMutation, useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { useGlobal } from "../../contexts";
import { API } from "../../clients/api";
import { useInView } from "react-intersection-observer";
import produce from 'immer';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Navbar,
  Header,
  Footer,
  PageWrapper,
  PageContent,
  Card,
  LoadingSpinner
} from "../../components";



import ImageItem3 from "../../assets/images/organon/item-3.png";
import defaultAvatar from "../../assets/images/avatar/default.jpg";

const CardFooter = ({ is_marked, profile, onCheck }) => (
  <div className="card-footer">
    <div className="row align-items-center g-0">
      <div className="col-auto">
        <img
          src={profile.avatar}
          className="rounded-circle avatar-xs"
          alt=""
        />
      </div>
      <div className="col ms-2">
        <span>{profile.name}</span>
      </div>
      <div className="col-auto">
        <a onClick={onCheck} className="text-muted bookmark mark-button">
          <i
            className="material-icons"
            style={{
              fontSize: "1rem",
              verticalAlign: "middle",
              color: is_marked ? "#754FFE" : "",
            }}
          >
            bookmark
          </i>
        </a>
      </div>
    </div>
  </div>
);

export const Home = () => {
  const { token } = useGlobal();
  const { ref, inView, entry } = useInView();

  const { data: categories, fetchNextPage, isFetching } = useInfiniteQuery(
    "categories",
    async ({ queryKey: [key, filter], pageParam = 1 }) => {
      const response = await API.fetchCategories(token, {
        ...filter,
        coursesLimit: 5,
        categoriesLimit: 3,
        page: pageParam
      });

      const data = typeof response.data === 'object' ? response.data : [];

      return { data, nextCursor: pageParam + 1 };
    },
    {
      initialData: [],
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    }
  );

  const queryClient = useQueryClient();

  const markCourseFn = async ({ courseId }) => {
    const { data } = await API.markCourse(token, courseId);

    return data;
  }

  const unmarkCourseFn = async ({ courseId }) => {
    const { data } = await API.unmarkCourse(token, courseId);

    return data;
  }

  const { mutate: markCourse } = useMutation(markCourseFn, {
    onMutate: async ({ courseId, categoryId, pageIndex }) => {
      await queryClient.cancelQueries("categories")
      const previousTodos = queryClient.getQueryData("categories")

      queryClient.setQueryData(
        "categories",
        produce((draft) => {
          const page = draft.pages[pageIndex];
          const category = page.data.find((category) => category.id === categoryId);
          const course = category.courses.find((course) => course.id === courseId);

          course.is_marked = true;
        })
      )

      return { previousTodos }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData("categories", context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries("categories")
    },
  })

  const { mutate: unmarkCourse } = useMutation(unmarkCourseFn, {
    onMutate: async ({ courseId, categoryId, pageIndex }) => {
      await queryClient.cancelQueries("categories")
      const previousTodos = queryClient.getQueryData("categories")

      queryClient.setQueryData(
        "categories",
        produce((draft) => {
          const page = draft.pages[pageIndex];
          const category = page.data.find((category) => category.id === categoryId);
          const course = category.courses.find((course) => course.id === courseId);

          course.is_marked = false;
        })
      )

      return { previousTodos }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData("categories", context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries("categories")
    },
  })

  const isLaptop = useMediaQuery({ maxWidth: 1020 });
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { isToggled, handleToggle } = useGlobal();

  const config = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : isLaptop ? 2 : 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (inView && categories.pages) {
      fetchNextPage();
    }
  }, [inView]);

  const hasNextData = categories &&
    categories.pages &&
    categories.pages.length &&
    categories.pages[categories.pages.length - 1].data.length;

  const { data: banners } = useQuery(
    'banners',
    async () => {
      const { data } = await API.fetchBanners(token);

      return Array.isArray(data) ? data : [];
    },
    { initialData: [] }
  );

  const homeConfig = {
    infinite: true,
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false
  };

  return (
    <PageWrapper className={isToggled ? "toggled" : ""}>
      <Navbar />

      <PageContent>
        <Header onToggle={handleToggle}>header</Header>

        <div>
          <div className="container-fluid">
            <div className='row mb-4'>
              <div className='col'>
                <Slider {...homeConfig}>
                  {
                    banners.map(({ id, banner_url }) => (
                      <div key={id} className='item'>
                        <img src={banner_url} className='img-fluid' />
                      </div>
                    ))
                  }
                </Slider>
              </div>
            </div>

            {!categories ? (
              <p>Carregando...</p>
            ) : (
              <>
                {
                  categories &&
                  categories.pages &&
                  categories.pages.map(({ data = [] }, pageIndex) => {
                    return data && data.length ? data.map(({ id: categoryId, name, courses = [] }) => {
                      const slidesToShow = courses.length >= config.slidesToShow
                        ? config.slidesToShow
                        : courses.length;

                      const slidesConfig = {
                        ...config,
                        slidesToShow,
                      };

                      if (!courses || !courses.length) {
                        return null;
                      }

                      return (
                        <div key={categoryId} className="row mb-11">
                          <div className="col-12">
                            <div id="category-slider">
                              <h2 className="text-black display-6">{name}</h2>
                              <Slider {...slidesConfig}>
                                {courses.map(
                                  ({
                                    id,
                                    title,
                                    difficulty,
                                    thumbnail,
                                    author = {},
                                    is_rating_enabled,
                                    is_marked,
                                    ratingAverage,
                                    ratings
                                  }) => (
                                    <div key={id} className={`item ${slidesToShow < 2 ? 'item-no-slider' : ''}`}>
                                      <Card
                                        id={id}
                                        title={title}
                                        is_marked={is_marked}
                                        image={thumbnail && typeof thumbnail === 'string' ? thumbnail : ImageItem3}
                                        stars={is_rating_enabled ? ratingAverage : -1}
                                        difficulty={difficulty}
                                        subscribers={ratings}
                                        link={`/curso/${id}`}
                                        profile={{
                                          name: `${author ? author.firstname : ''} ${author ? author.surname : ''}`,
                                          avatar:
                                            author && author.profile_picture ? author.profile_picture : defaultAvatar,
                                        }}
                                        onCheck={
                                          () => {
                                            is_marked
                                              ? unmarkCourse({ courseId: id, categoryId, pageIndex })
                                              : markCourse({ courseId: id, categoryId, pageIndex })
                                          }
                                        }
                                        Components={{ Footer: CardFooter }}
                                      />
                                    </div>
                                  )
                                )}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      );
                    }) : null
                  })
                }
                <LoadingSpinner show={isFetching} />
                {
                  hasNextData ? (
                    <div ref={ref} style={{ height: 10, width: "100%" }} />
                  ) : null
                }
              </>
            )}
          </div>
        </div>

        <Footer />
      </PageContent>
    </PageWrapper>
  );
};
