import "./loading.scss";

export const LoadingSpinner = ({ show }) => {
  return (
    <div
      className="spinner-container"
      style={{ display: show ? "flex" : "none" }}
    >
      <div className="loading-spinner"></div>
    </div>
  );
};
