import { Link, useNavigate } from 'react-router-dom';
import { SignBanner, AccountCard } from '../../components';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useGlobal } from '../../contexts';
const BASE_URL = process.env.REACT_APP_BASE_API;


export const Cadastro = () => {
  const navigate = useNavigate();
  const { setToken } = useGlobal()
  const [user, setUser] = useState(generateInitialUser);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeUser = (key) => (e) => setUser((old) => ({ ...old, [key]: e.target.value }));

  const handleCreate = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const clonedUser = { ...user };
    const validation = validateUser(clonedUser);
    delete clonedUser.confirmEmail;
    delete clonedUser.confirmPassword;

    if (validation.isValid) {
      fetch(
        `${BASE_URL}/register`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify(clonedUser)
        }
      ).then(async (response) => {
        if (response) {
          const { data } = await response.json();

          if (data && typeof data === 'object') {
            toast.success('Sucesso ao cadastrar usuário!');
            setIsLoading(false);
            setToken(data.token);
            navigate('/');
          } else {
            toast.error('Falha ao cadastrar usuário!');
            setIsLoading(false);
          }
        }
      })
    } else {
      toast.error(validation.msg);
      setIsLoading(false);
    }
  }

  return (
    <div className='account-page-wrapper'>
      <SignBanner />

      <AccountCard>
        <p className='display-5 text-black'>Cadastrar</p>

        <p className='fs-5 fw-bold text-muted mb-5'>Já possui uma conta? <Link to='/' className='ms-1'>Login</Link></p>

        <form onSubmit={handleCreate} className='row'>
          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Nome</label>
            <input type="text" value={user.firstname} onChange={handleChangeUser('firstname')} id="firstname" className="form-control" placeholder="Email" required />
          </div>

          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Sobrenome</label>
            <input type="text" id="surname" value={user.surname} onChange={handleChangeUser('surname')} className="form-control" placeholder="Email" required />
          </div>

          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Telefone</label>
            <input type="text" id="phone" value={user.phone} onChange={handleChangeUser('phone')} className="form-control" placeholder="Email" required />
          </div>

          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Email</label>
            <input type="email" id="email" value={user.email} onChange={handleChangeUser('email')} className="form-control" placeholder="Email" required />
          </div>

          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Confirmar Email</label>
            <input type="confirmEmail" value={user.confirmEmail} onChange={handleChangeUser('confirmEmail')} id="confirmEmail" className="form-control" placeholder="Email" required />
          </div>

          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Senha</label>
            <input type="password" value={user.password} onChange={handleChangeUser('password')} id="password" className="form-control" placeholder="Senha" required />
          </div>

          <div className="mb-5 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Confirmar Senha</label>
            <input type="password" value={user.confirmPassword} onChange={handleChangeUser('confirmPassword')} id="confirmPassword" className="form-control" placeholder="Confirmar Senha" required />
          </div>

          <div className="mb-5 col-12">
            <button disabled={isLoading} className='btn btn-primary text-uppercase' style={{ width: '100%' }}>Cadastrar</button>
          </div>
        </form>
      </AccountCard>
    </div>
  );
}

const generateInitialUser = () => ({
  "firstname": "",
  "surname": "",
  "email": "",
  "password": "",
  "phone": "",
  "client_type": "",
  "speciality": "",
  "CRT": "",
  "address_1": "",
  "accepted_terms": false,
  "privacy_policy": false,
  "disable_notifications": false,
  "status": "online",
  confirmPassword: '',
  confirmEmail: '',
  role_id: 3,
  country_id: 1,
  state_id: 1
})

const validateUser = (user) => {
  let validation = {
    isValid: true,
    msg: ''
  };

  if (user['password'] !== user['confirmPassword']) {
    return {
      isValid: false,
      msg: 'Verifique confirmação de senha'
    };
  }

  if (user['email'] !== user['confirmEmail']) {
    return {
      isValid: false,
      msg: 'Verifique confirmação de email'
    };
  }

  if (!user['email']) {
    return {
      isValid: false,
      msg: 'Campo email é obrigatório'
    };
  }

  if (user['phone'].length < 14) {
    return {
      isValid: false,
      msg: 'São necessários no minímo 14 digitos no campo telefone'
    };
  }

  if (user['phone'].length > 14) {
    return {
      isValid: false,
      msg: 'São permitidos no máximo 15 digitos no campo telefone'
    };
  }

  if (user['password'].length < 8) {
    return {
      isValid: false,
      msg: 'São necessários no minímo 8 digitos no campo senha'
    };
  }

  if (user['surname'].length < 3) {
    return {
      isValid: false,
      msg: 'São necessários no minímo 3 digitos no campo segundo nome'
    };
  }

  if (user['firstname'].length < 3) {
    return {
      isValid: false,
      msg: 'São necessários no minímo 3 digitos no campo primeiro nome'
    };
  }

  if (!user['email']) {
    return {
      isValid: false,
      msg: 'Campo email é obrigatório'
    };
  }


  return validation;
}