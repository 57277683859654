export const Star = ({ full, half, className = '', outlined = false, ...props }) => {


  return (
    <i className={`${outlined ? 'material-icons-outlined' : 'material-icons'} star-rating ${full ? 'full' : ''} ${half ? 'half' : ''} ${className}`} {...props}>
      {
        outlined
          ? 'grade'
          : 'star'
      }
    </i>
  )
}