import { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGlobal } from '../../contexts';
import { SignBanner, AccountCard } from '../../components';

export const Login = () => {
  const navigate = useNavigate();
  const { signin } = useGlobal();

  const loginRef = useRef(null);
  const passwordRef = useRef(null);

  const handleLogin = (e) => {
    e.preventDefault();

    signin({
      email: loginRef.current.value,
      password: passwordRef.current.value
    }, (token) => {
      if (token) {
        navigate('/');
      } else {
        toast.error('Falha ao realizar o login por dados de Usuário e/ou Senha errados.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        passwordRef.current.value = '';
      }
    })
  }

  useEffect(() => {
    window.DigitalIdentityHub.start();
  }, [])

  return (
    <div className='account-page-wrapper'>
      <SignBanner />

      <AccountCard>
        <div id="DigitalIdentityHubHost"></div>
        <div id="ConsentHubHost"></div>
        <div id="LanguageSelector"></div>
        <div id="flow-version"></div>

        {/* <p className='display-5 text-black'>Entrar</p>

        <p className='fs-5 fw-bold text-muted mb-5'>Não tem uma conta? <Link to='/cadastro' className='ms-1'>Inscrever-se</Link></p>

        <form className='row' onSubmit={handleLogin}>
          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="lastname">Nome de usuário ou email</label>
            <input ref={loginRef} type="email" id="lastname" className="form-control" placeholder="Nome ou email aqui..." required />
          </div>

          <div className="mb-3 col-12">
            <label className="form-label fs-5 fw-bold text-dark" htmlFor="passoword">Senha</label>
            <input ref={passwordRef} type="password" id="passoword" className="form-control" placeholder="**************" required />
          </div>

          <div className="mb-4 col-12 d-flex justify-content-between fs-5 fw-bold">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="remember" />
              <label className="form-check-label text-muted" htmlFor="remember">Lembrar</label>
            </div>

            <Link to='/esqueceu-senha'>Esqueceu sua senha?</Link>
          </div>

          <div className="mb-5 col-12">
            <button className='btn btn-primary text-uppercase bg-blue border-blue w-100'>Entrar</button>
          </div>
        </form> */}
      </AccountCard>
    </div>
  );
}