import { useState, useMemo, useEffect, useRef } from 'react';
import { useTable, useFlexLayout } from 'react-table'

import { useGlobal } from '../../contexts';
import { Navbar, Header, PageWrapper, PageContent, Star, Footer } from '../../components';
import { API } from '../../clients/api';
import Swal from 'sweetalert2';
import produce from 'immer';
import { toast } from 'react-toastify';

const labels = {
  importante: {
    type: 'danger',
    text: 'Importante'
  },
  social: {
    type: 'success',
    text: 'Social'
  },
  promocoes: {
    type: 'warning',
    text: 'Promoções'
  }
}

const title = {
  'novo-curso': "Novo Curso",
  'novo-comentário': "Novo Comentário"
}

export const Alertas = () => {
  const { isToggled, handleToggle, token } = useGlobal();
  const [currentTab, setCurrentTab] = useState('edit');
  const [alerts, setAlerts] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [textFilter, setTextFilter] = useState('');

  const fetchAlerts = async (params = {}) => {
    const { data = [] } = await API.getAlerts(token, params);

    if (Array.isArray(data)) {
      setAlerts(data);
    }
  }

  useEffect(() => {
    fetchAlerts()
  }, [])

  const columns = useMemo(
    () => [
      {
        width: 10,
        Header: 'Column 1',
        accessor: 'uuid',
        id: 'checked',
        Cell: ({ value }) => (
          <input type='checkbox' onChange={handleCheck(value)} checked={selecteds.includes(value)} />
        )
      },
      {
        width: 10,
        Header: 'Column 2',
        accessor: 'starred',
        Cell: ({ value }) => (
          <Star full={value} outlined={!value} className='fs-3' />
        )
      }, {
        width: 80,
        Header: 'Column 2',
        accessor: 'title',
        Cell: ({ value }) => <p className='text-truncate fw-bold mb-0'>{title[value] || value}</p>
      }, {
        width: 200,
        Header: 'Column 2',
        accessor: 'text',
        Cell: ({ value, }) => <p className='text-truncate fw-bold mb-0'>{value}</p>
      }, {
        width: 60,
        minWidth: 40,
        Header: 'Column 2',
        accessor: 'is_read',
        Cell: ({ value }) => value ? <span className="badge bg-success mb-0">Lido</span> : <span className="badge bg-danger mb-0">Não lido</span>
      }, {
        width: 80,
        minWidth: 40,
        Header: 'Column 2',
        accessor: 'createdAt',
        Cell: ({ value }) => <p className='fw-medium text-end mb-0'>{new Date(value).toLocaleString('pt-BR')}</p>
      },
    ],
    [selecteds]
  )
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = useTable({
    columns, data: alerts.map((alert) => ({ ...alert.Notification, is_read: alert.is_read }))
  }, useFlexLayout)

  const handleCheckAll = (e) => setSelecteds(e.target.checked ? alerts.map((alert) => alert.Notification.uuid) : []);
  const handleCheck = (id) => (e) => setSelecteds((old) => e.target.checked ? [...old, id] : old.filter((check) => check !== id))

  const filterRef = useRef(null);
  const handleFilter = (e) => {
    setTextFilter(e.target.value);

    if (filterRef.current) {
      clearTimeout(filterRef.current);
    }

    filterRef.current = setTimeout(() => {
      fetchAlerts({ filter: textFilter });
    }, 500);
  }

  const handleReadAlerts = () => {
    setDropdown(false)
    const clonedAlerts = { ...alerts };
    setAlerts(
      produce((draft) => {
        for (const alert of draft) {
          if (selecteds.includes(alert.Notification.uuid)) {
            alert.is_read = true;
          }
        }
      })
    )

    API.readAlert(token, { notifications: selecteds, is_read: true })
      .then(() => {
        toast.success('Sucesso ao marcar notificações como lidas!');
      })
      .catch(() => {
        toast.error('Erro ao marcar notificações como lidas!');
        setAlerts(clonedAlerts)
      })

  }

  const handleUnreadAlerts = () => {
    setDropdown(false)
    const clonedAlerts = { ...alerts };
    setAlerts(
      produce((draft) => {
        for (const alert of draft) {
          if (selecteds.includes(alert.Notification.uuid)) {
            alert.is_read = false;
          }
        }
      })
    )

    API.readAlert(token, { notifications: selecteds, is_read: false })
      .then(() => {
        toast.success('Sucesso ao marcar notificações como não lidas!')
      })
      .catch(() => {
        toast.success('Erro ao marcar notificações como não lidas!')
        setAlerts(clonedAlerts)
      })
  }

  const handleRemoveAlerts = () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const clonedAlerts = { ...alerts };
        setAlerts(
          produce((draft) => {
            for (const selected of selecteds) {
              const index = draft.findIndex((alert) => alert.Notification.uuid === selected);

              draft.splice(index, 1);
            }
          })
        )

        API.removeAlert(token, selecteds)
          .then(() => {
            toast.success('Sucesso ao remover notificações!');
          })
          .catch(() => {
            setAlerts(clonedAlerts);
            toast.error('Falha ao remover notificações!');
          })
      }
    })

    setDropdown(false);
  }

  return (
    <PageWrapper className={isToggled ? 'toggled' : ''}>
      <Navbar />

      <PageContent className='alerts'>
        <Header
          onToggle={handleToggle}
        />

        <div className='container-xl'>
          <div className='row my-5 mb-12'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body pb-3'>
                  <div className='d-flex align-items-center px-4'>
                    <div className="dropdown">
                      <button onClick={() => setDropdown(!dropdown)} className='btn btn-outline-white d-flex align-items-center me-2' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <input onChange={handleCheckAll} checked={selecteds.length === alerts.length} type='checkbox'></input>
                        <i className="fe fe-chevron-down ms-2"></i>
                      </button>

                      <ul className="dropdown-menu" style={{ display: selecteds && selecteds.length && dropdown ? 'block' : 'none' }} aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item cursor-pointer" onClick={handleReadAlerts}>Marcar como Lido</a></li>
                        <li><a className="dropdown-item cursor-pointer" onClick={handleUnreadAlerts}>Marcar como Não Lido</a></li>
                        <li><a className="dropdown-item cursor-pointer" onClick={handleRemoveAlerts}>Excluir</a></li>
                      </ul>
                    </div>

                    <div className="ms-auto">
                      <input type="text" value={textFilter} onChange={handleFilter} id="textInput" className="form-control form-control-sm" placeholder="Procure email" />
                    </div>
                  </div>
                  <hr className='mb-0'></hr>
                  <div className='table-responsive'>


                    <table {...getTableProps()} className='table' style={{ minWidth: 724 }}>
                      <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className='d-flex align-items-center px-4'>
                    <p className='mb-0 me-auto'>
                      Mostrando {alerts ? alerts.length : 0}
                    </p>

                    <button className='btn btn-outline-white btn-sm me-1'>
                      <i className="fe fe-chevron-left"></i>
                    </button>

                    <button className='btn btn-outline-white btn-sm'>
                      <i className="fe fe-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </PageContent>
    </PageWrapper>
  )
}