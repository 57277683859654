export const SignBanner = () => (
  <div className='sigin-banner'>
    <div className='sigin-banner-fitter'>
      <i className='sigin-banner-item' style={{ bottom: '-20px', left: '0%' }}>
        <svg height="81px" width="97px">
          <path d="M0,0 L56,0 A1,1 0 1,1 56,81 L0,81 Z" fill="#1B4298" />
        </svg>
      </i>

      <i className='sigin-banner-item' style={{ top: '14%', right: '0%' }}>
        <svg height="81px" width="128px">
          <path d="M126,81 L42,81 A1,1 0 1,1 42,0 L126,0 Z" fill="#B0EAFF" />
        </svg>
      </i>

      <i className='sigin-banner-item' style={{ top: '-5.5em', left: '-5.5em' }}>
        <svg height="11em" width="11em">
          <circle cx="5.5em" cy="5.5em" r="5.5em" fill="#EDDD34" />
        </svg>
      </i>

      <i className='sigin-banner-item' style={{ top: '36%', left: '20%' }}>
        <svg height="5.8em" width="5.8em">
          <circle cx="2.9em" cy="2.9em" r="2.9em" fill="#54B948" />
        </svg>
      </i>

      <i className='sigin-banner-item' style={{ top: '-2.9em', left: '75%' }}>
        <svg height="5.8em" width="5.8em">
          <circle cx="2.9em" cy="2.9em" r="2.9em" fill="#3CDBC0" />
        </svg>
      </i>

      <i className='sigin-banner-item' style={{ bottom: '-3.5em', left: '75%' }}>
        <svg height="11em" width="11em">
          <circle cx="5.5em" cy="5.5em" r="5.5em" fill="#E41782" />
        </svg>
      </i>
    </div>
  </div>
)