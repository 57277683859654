import { useState } from "react";

export const NavlinkCollapse = ({
  title,
  icon = "",
  id,
  children,
  disabled,
  hidden,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleLink = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <a
        href="#"
        className={`nav-link 
        ${!collapsed ? "collapsed" : ""} 
        ${disabled ? "disabled" : ""} 
        ${hidden ? "hidden" : ""}`}
        data-bs-toggle="collapse"
        aria-expanded={collapsed}
        aria-controls={id}
        onClick={handleToggleLink}
        {...props}
      >
        {icon}
        {title}
      </a>

      <div id={id} className={`collapse ${collapsed ? "show" : ""}`}>
        {children}
      </div>
    </>
  );
};
