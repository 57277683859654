import { Routes, Route } from "react-router-dom";
import { GlobalContextProvider } from "./contexts";
import { ToastContainer } from "react-toastify";
import { QueryProvider, RequireAuth } from "./contexts";
import { ModalAccepts } from "./components";
import {
  Login,
  Alertas,
  Home,
  Lista,
  Painel,
  Perfil,
  NotFound,
  Cadastro,
  RecoverAccount,
  ForgotPassword,
  Curso,
  Aula,
} from "./pages";

function App() {

  return (
    <div className="App">
      <QueryProvider>
        <GlobalContextProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/index.html" element={<Login />} />
            <Route path="/esqueceu-senha" element={<ForgotPassword />} />
            <Route path="/recuperar-conta" element={<RecoverAccount />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/home" element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            } />
            <Route path="/" element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            } />

            <Route path="/curso/:courseId" element={
              <RequireAuth>
                <Curso />
              </RequireAuth>
            } />
            <Route
              path="/curso/:courseId/aula/:lessonId"
              element={
                <RequireAuth>
                  <Aula />
                </RequireAuth>
              }
            />
            <Route path="/lista-de-conteudo" element={
              <RequireAuth>
                <Lista />
              </RequireAuth>
            } />
            <Route path="/meu-painel" element={
              <RequireAuth>
                <Painel />
              </RequireAuth>
            } />
            <Route path="/perfil" element={
              <RequireAuth>
                <Perfil />
              </RequireAuth>
            } />
            <Route path="/notificacoes" element={
              <RequireAuth>
                <Alertas />
              </RequireAuth>
            } />

            <Route path="*" element={<NotFound />} />
          </Routes>

          <ModalAccepts />
        </GlobalContextProvider>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </QueryProvider>
    </div>
  );
}

export default App;
