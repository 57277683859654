import { useState, useEffect, useContext, createContext } from "react";
import { useQuery, } from "react-query";
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { API } from "../../clients/api";
import Swal from 'sweetalert2';

const BASE_URL = process.env.REACT_APP_BASE_API;

const GlobalContext = createContext(null);

export const GlobalContextProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [isToggled, setIsToggled] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || searchParams.get('token') || '');

  const { data: user, isLoading: isUserLoading } = useQuery(
    ['users', 'me', { token }],
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/users/me`, { headers })
      const { data } = await response.json();

      if (data && typeof data === 'object') {
        return data;
      } else {
        setToken('');
        return null
      }
    }, {
    initialData: null,
    enabled: !!token
  })


  useEffect(() => {
    localStorage.setItem('token', token);
  }, [token])

  let signin = (user, callback = () => { }) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch(
      `${BASE_URL}/login`,
      {
        headers,
        method: 'POST',
        body: JSON.stringify({
          ...user,
          uuid: user.password
        })
      }
    ).then(async (response) => {
      let token = '';
      const { data = {} } = await response.json();

      if (data && typeof data === 'object') {
        token = data.token;
        setToken(token)
      }

      callback(token);
    })
  };

  let signout = (callback = () => { }) => {
    Swal.fire({
      title: 'Deseja sair da aplicação?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        setToken('');
        callback();
      }
    })
  };

  const handleToggle = () => setIsToggled(!isToggled);

  return (
    <GlobalContext.Provider
      value={{
        signin,
        signout,
        token,
        setToken,
        isToggled,
        handleToggle,
        user,
        isUserLoading,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);

export const RequireAuth = ({ children }) => {
  let { token } = useGlobal();
  let location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}