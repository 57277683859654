import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  updateMarkedCourseBookMark,
  updateUnmarkedCourseBookMark,
} from "../../redux/thunx/courses.thunx";
import { Star } from "../";
import { getStarProps } from "../../helpers";
import { useGlobal } from "../../contexts";

const DefaultFooter = ({ id: courseId, is_marked, profile }) => {
  const dispatch = useDispatch();
  const { token } = useGlobal();

  function toggleBookMark() {
    if (!is_marked) {
      dispatch(updateMarkedCourseBookMark({ token, courseId }));
    } else {
      dispatch(updateUnmarkedCourseBookMark({ token, courseId }));
    }
  }

  function handleClick() {
    toggleBookMark();
  }

  return (
    <div className="card-footer">
      <div className="row align-items-center g-0">
        <div className="col-auto">
          <img
            src={profile.avatar}
            className="rounded-circle avatar-xs"
            alt=""
          />
        </div>
        <div className="col ms-2">
          <span>{profile.name}</span>
        </div>
        <div className="col-auto">
          <a onClick={handleClick} className="text-muted bookmark mark-button">
            <i
              className="material-icons"
              style={{
                fontSize: "1rem",
                verticalAlign: "middle",
                color: is_marked ? "#754FFE" : "",
              }}
            >
              bookmark
            </i>
          </a>
        </div>
      </div>
    </div>
  );
};

const levels = {
  beignners: {
    label: 'Iniciante',
    rating: 1
  },
  intermediate: {
    label: 'Intermediário',
    rating: 2
  },
  advanced: {
    label: 'Avançado',
    rating: 3
  }
}

export const Card = (props) => {
  const {
    id = "",
    title = "",
    image = "",
    stars = "",
    link = "/",
    is_marked = false,
    difficulty = "",
    subscribers = 0,
    profile = {},
    Components: { Footer = DefaultFooter } = {},
  } = props;

  const level = levels[difficulty] || levels.beignners;

  return (
    <div className="card card-hover">
      <Link to={link} className="card-img-top">
        <img src={image} alt="" style={{ maxHeight: 160, objectFit: 'cover' }} className="rounded-top-md card-img-top" />
      </Link>
      <div className="card-body">
        <h4 className="mb-2 text-truncate-line-2 " style={{ wordBreak: 'break-all' }}>
          <Link to={link} className="text-inherit">
            {title}
          </Link>
        </h4>
        <ul className="mb-3 list-inline">
          <li className="list-inline-item">
            <i className="mdi mdi-clock-time-four-outline text-muted me-1"></i>
            {/* 3h 56m */}
          </li>
          <li className="list-inline-item">
            <svg
              className="me-1 mt-n1"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="3" y="8" width="2" height="6" rx="1" fill={level.rating >= 1 ? "#754FFE" : "#DBD8E9"} />
              <rect x="7" y="5" width="2" height="9" rx="1" fill={level.rating >= 2 ? "#754FFE" : "#DBD8E9"} />
              <rect x="11" y="2" width="2" height="12" rx="1" fill={level.rating >= 3 ? "#754FFE" : "#DBD8E9"} />
            </svg>
            {level.label}
          </li>
        </ul>
        <div
          className="lh-1"
          style={{ visibility: stars == -1 ? "hidden" : "flex" }} n
        >
          <span>
            {stars != -1 &&
              Array(5)
                .fill(null)
                .map((val, index) => (
                  <Star key={index} {...getStarProps(index, stars)} />
                ))}
          </span>
          <span className="text-warning">{stars && stars != -1 && Number(stars).toFixed(2)}</span>
          <span className="fs-6 text-muted">
            ({stars != -1 && subscribers})
          </span>
        </div>
      </div>

      <Footer {...props} />
    </div>
  );
};

export const CardRow = (props) => {
  const {
    id = "",
    title = "",
    image = "",
    stars = "",
    link = "/",
    is_marked = false,
    difficulty = "",
    subscribers = 0,
    profile = {},
    Components: { Footer = DefaultFooter } = {},
  } = props;

  const level = levels[difficulty] || levels.beignners;

  return (
    <div className="card card-hover">
      <div className="row g-0">
        <Link to={link} className="col-12 col-md-12 col-xl-3 col-lg-3 bg-cover img-left-rounded" style={{ backgroundImage: `url(${image})` }}>
          <img src={image} alt="" className="img-fluid d-lg-none invisible" />
        </Link>

        <div className="col-lg-9 col-md-12 col-12">
          <div className="card-body">
            <h4 className="mb-2 text-truncate-line-2 ">
              <Link to={link} className="text-inherit">
                {title}
              </Link>
            </h4>
            <ul className="mb-3 list-inline">
              <li className="list-inline-item">
                <i className="mdi mdi-clock-time-four-outline text-muted me-1"></i>
                3h 56m
              </li>
              <li className="list-inline-item">
                <svg
                  className="me-1 mt-n1"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="3" y="8" width="2" height="6" rx="1" fill={level.rating >= 1 ? "#754FFE" : "#DBD8E9"} />
                  <rect x="7" y="5" width="2" height="9" rx="1" fill={level.rating >= 2 ? "#754FFE" : "#DBD8E9"} />
                  <rect x="11" y="2" width="2" height="12" rx="1" fill={level.rating >= 3 ? "#754FFE" : "#DBD8E9"} />
                </svg>
                {level.label}
              </li>
            </ul>
            <div
              className="lh-1"
              style={{ visibility: stars == -1 ? "hidden" : "flex" }}
            >
              <span>
                {stars != -1 &&
                  Array(5)
                    .fill(null)
                    .map((val, index) => (
                      <Star key={index} {...getStarProps(index, stars)} />
                    ))}
              </span>
              <span className="text-warning">{stars && stars != -1 && Number(stars).toFixed(2)}</span>
              <span className="fs-6 text-muted">
                ({stars != -1 && subscribers})
              </span>
            </div>
          </div>

          <Footer {...props} />
        </div>
      </div>
    </div>
  );
};
