import React, { useState } from "react";
import { useGlobal } from "../../contexts";
import {
  Navbar,
  Header,
  PageWrapper,
  PageContent,
  ProfileHeader,
  Footer,
} from "../../components";
import Default from "../../assets/images/avatar/default.jpg";

import { Nav } from "react-bootstrap";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { ProfileInfoForm } from "./ProfileInfoForm";
import { toast } from 'react-toastify';
import { API } from "../../clients/api";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

export const Perfil = () => {
  const { token, signout } = useGlobal();
  const [avatar, setAvatar] = useState();
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const navigate = useNavigate();

  const { data: user, isFetching } = useQuery("profile", async () => {
    const { data = [] } = await API.fetchUserInfo(token);

    return typeof data === 'object' ? data : {};
  });

  const queryClient = useQueryClient();

  const UserInfo = (field) => {
    const { user } = useGlobal();

    if (!user || !user.id) {
      return "";
    }

    if (field == "fullname") {
      return `${user.firstname} ${user.surname}`;
    } else if (field == "description") {
      return `@${user.firstname}_${user.surname}`;
    } else if (field == "all") {
      return user;
    }
  };

  const userUpdate = useMutation(
    async (userData) => {
      const { data } = await API.updateUserInfo(token, userData);

      return data;
    },
    {
      onError: (error) => {
        userUpdate.reset();
        toast.error('Falha ao atualizar perfil de usuário!');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['users', 'me']);
        queryClient.invalidateQueries('profile');
        toast.success('Sucesso ao atualizar perfil de usuário!');
      },
    }
  );

  const { isToggled, handleToggle } = useGlobal();
  const [currentTab, setCurrentTab] = useState("edit");

  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  const handleChangeAvatar = (e) => {
    const fileReader = new FileReader();
    const [file] = e.target.files;
    console.log({ file })
    fileReader.onloadend = () => setAvatar(fileReader.result);
    fileReader.readAsDataURL(file);
  };

  const handleConfirmAvatar = () => {
    setIsLoadingAvatar(true);

    Swal.fire({
      title: 'Deseja atualizar imagem de perfil?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { secure_url } = await API.uploadFile(token, avatar);

        if (secure_url) {
          userUpdate.mutate({ profile_picture: secure_url }, {
            onSettled: () => {
              setIsLoadingAvatar(false);
              setAvatar(null);
            }
          });
        }
      } else {
        setIsLoadingAvatar(false);

      }
    })
  }

  const handleDeleteAvatar = () => {
    setIsLoadingAvatar(true);

    Swal.fire({
      title: 'Deseja excluir imagem de perfil?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d1d1d1',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        userUpdate.mutate({ profile_picture: '' }, {
          onSettled: () => {
            setIsLoadingAvatar(false);
          }
        });
      } else {
        setIsLoadingAvatar(false);
      }
    })
  }

  return (
    <PageWrapper className={isToggled ? "toggled" : ""}>
      <Navbar />

      <PageContent className="profile">
        <Header onToggle={handleToggle} />

        <div className="container-xl">
          <div className="row my-5">
            <div className="col-12">
              <ProfileHeader
                avatar={user && user.profile_picture ? user.profile_picture : Default}
                title={UserInfo("fullname")}
                description={UserInfo("description")}
                btnTitle="Meu Painel"
                onClickButton={() => navigate('/meu-painel')}
              />
            </div>
          </div>

          <div className="row g-3 mb-12">
            <div className="col-xl-3">
              <div className="card">
                <div className="card-body card-tabs">
                  <p className="card-tabs_title text-gray">
                    Configurações de conta
                  </p>
                  <Nav
                    onSelect={handleSelectTab}
                    variant="pills"
                    className="flex-column"
                  >
                    <Nav.Item>
                      <Nav.Link
                        className="card-tabs_link"
                        active={isCurrentTab("edit")}
                        eventKey="edit"
                      >
                        <i className="fe fe-settings me-2"></i>
                        Editar Perfil
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="card-tabs_link"
                        active={isCurrentTab("remove")}
                        eventKey="remove"
                      >
                        <i className="fe fe-power me-2"></i>
                        Excluir Perfil
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="card-tabs_link"
                        active={isCurrentTab("logout")}
                        eventKey="logout"
                        onClick={() => signout()}
                      >
                        <i className="fe fe-user me-2"></i>
                        Sair
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="card card-profile">
                <div className="card-header">
                  <h3 className="display-6 text-dark fw-medium mb-2">
                    Detalhes de perfil
                  </h3>
                  <p className="fw-medium mb-0">
                    Você tem controle total para gerenciar sua própria
                    configuração de conta
                  </p>
                </div>
                <div className="card-body">
                  <div className="card-profile_avatar mb-5">
                    <label className="cursor-pointer">
                      <img
                        src={avatar ? avatar : user && user.profile_picture ? user.profile_picture : Default}
                        className="rounded-circle avatar-xl"
                        alt=""
                      />
                      <input onChange={handleChangeAvatar} type="file" className="d-none" />
                    </label>

                    <div className="card-profile_avatar-text">
                      <h4 className="mb-0 text-dark">Seu Avatar</h4>

                      <p className="mb-0 fw-medium">
                        PNG ou JPG não maior que 800px de largura e altura.
                      </p>
                    </div>

                    <div className="card-profile_avatar-buttons d-flex">
                      <button
                        onClick={handleConfirmAvatar}
                        disabled={isLoadingAvatar}
                        className="btn btn-sm btn-outline-secondary me-2"
                        style={{ borderColor: "#dedede" }}
                      >
                        Atualizar
                      </button>

                      <button
                        onClick={handleDeleteAvatar}
                        disabled={isLoadingAvatar}
                        className="btn btn-sm btn-outline-danger"
                      >
                        Excluir
                      </button>
                    </div>
                  </div>
                  <hr></hr>

                  <h5 className="mb-0 text-dark">Detalhes Pessoais</h5>

                  <p className="fw-medium">
                    Edite suas informações pessoais e endereço.
                  </p>
                  <ProfileInfoForm loading={isFetching} values={user} />
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </PageContent>
    </PageWrapper>
  );
};
