import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useGlobal } from '../../contexts';
import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../clients/api';
import { useLocation, useNavigate } from 'react-router-dom'


const publicRoutes = ['/login', '/esqueceu-senha', '/cadastro'];

export const ModalAccepts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user, token, signout, setUser } = useGlobal();
  const [termsContent, setTermsContent] = useState('');
  const [politics, setPolitics] = useState(false);
  const [terms, setTerms] = useState(false);
  const isOpen = location && !publicRoutes.includes(location.pathname) && user && user.id && (!user.privacy_policy || !user.accepted_terms);

  const mutation = useMutation(async () => await API.acceptTerms(token), {
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    }
  })

  useEffect(() => {
    if (isOpen) {
      (async () => {
        API
          .fetchTerms(token)
          .then(async ({ data }) => {
            if (typeof data === 'object') {
              setTermsContent(data.text);
            }
          })
      })()
    }
  }, [location, user])

  return (
    <Modal size='xl' show={isOpen}>
      <Modal.Header>
        <Modal.Title>Aceite</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: '70vh' }}>
        <div className='d-flex flex-column'>
          <h1 className='display-10'>Politicas de privacidade</h1>
          <div className='mb-4' style={{ maxHeight: '280px', overflow: 'auto' }}></div>

          <h1 className='display-10'>Termos de uso</h1>
          <div className='mb-4' style={{ maxHeight: '280px', overflow: 'auto' }}>
            <span dangerouslySetInnerHTML={{ __html: termsContent }} />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='d-flex'>
          <Form.Group className="me-4" controlId="politic">
            <Form.Check value={terms} onChange={e => setPolitics(e.target.checked)} type="checkbox" label="Aceitar Politicas" />
          </Form.Group>

          <Form.Group className="me-4" controlId="terms">
            <Form.Check value={politics} onChange={e => setTerms(e.target.checked)} type="checkbox" label="Aceitar termos de uso" />
          </Form.Group>
        </div>

        <a className='btn btn-danger' onClick={() => signout(() => navigate('/login'))}>
          Cancelar
        </a>

        <Button onClick={mutation.mutate} disabled={!politics || !terms} variant="primary">
          Concluir
        </Button>
      </Modal.Footer>
    </Modal>
  )
}